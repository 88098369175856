import { useEffect, useState, useCallback, useRef } from 'react';
import { CHANNELS } from '@utils/enums';
import { socket } from '@utils/socket';
import { appState } from '@utils/store';

const defaultLimit = 10;

const emit = (skip = 0, limit = defaultLimit) => socket.emit(CHANNELS.PLAYER_FREEBETS_PASSIVE, { skip, limit });

const setMyBets = ({ setItems, totalRef, setLoading }) => {
  const PLAYER_FREEBETS_PASSIVE = ({ docs, total }) =>
    setItems((prev) => {
      totalRef.current = total;
      setLoading(false);
      return [...prev, ...docs];
    });

  socket.on(CHANNELS.PLAYER_FREEBETS_PASSIVE, PLAYER_FREEBETS_PASSIVE);

  return () => {
    socket.off(CHANNELS.PLAYER_FREEBETS_PASSIVE, PLAYER_FREEBETS_PASSIVE);
  };
};

const useFreeBets = () => {
  const saved = appState.freeBets;
  const [items, setItems] = useState(saved.items || []);
  const [loading, setLoading] = useState(true);

  const totalRef = useRef(saved.total || 0);

  useEffect(() => {
    if (!items.length) emit();
    else setLoading(false);
  }, [items]);

  useEffect(() => {
    appState.freeBet = { items, total: totalRef.current };
  }, [items]);

  useEffect(() => setMyBets({ setItems, totalRef, setLoading }), []);

  const onLoadMore = useCallback(() => {
    setLoading(true);
    emit(items.length);
  }, [items.length]);

  const loadMore = items.length < totalRef.current ? onLoadMore : null;

  const freeBets = !items.length && loading ? null : items;

  return { freeBets, loading, loadMore };
};

export default useFreeBets;
