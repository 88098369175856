import { useState, useEffect, useMemo } from 'react';

const getElement = (selector) => document.getElementById(selector || 'root');

const useResolution = (elementId) => {
  const [resolution, setResolution] = useState(null);

  useEffect(() => {
    const windowResize = () => {
      setResolution(null);
      const { clientWidth, clientHeight } = getElement(elementId);
      setResolution({ width: clientWidth, height: clientHeight });
    };

    setTimeout(() => {
      windowResize();
      window.addEventListener('resize', windowResize);
    }, 0);

    return () => {
      window.removeEventListener('resize', windowResize);
    };
  }, [elementId]);

  return useMemo(() => resolution, [resolution]);
};

export default useResolution;

// FIXME: WARNING: Too many active  contexts. Oldest context will be lost.
