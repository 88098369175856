const User = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} fill="none">
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M12.044 14.315a4.986 4.986 0 1 0 0-9.971 4.986 4.986 0 0 0 0 9.971ZM14.377 14.617H9.834a4.666 4.666 0 0 0-4.666 4.666c0 .092 0 .185-.002.278-.014 1.034.826 1.784 1.86 1.784h10.16c1.033 0 1.873-.75 1.859-1.784a21.644 21.644 0 0 1-.002-.278 4.666 4.666 0 0 0-4.666-4.666ZM16.032 18.867v2.413M8.18 18.867v2.413"
    />
  </svg>
);
export default User;
