import { useRef, useLayoutEffect } from 'react';
import { getLocalize } from '@utils/locales';
import { appState } from '@utils/store';
import Typography from '@components/typography';
import styles from './index.module.css';

const l = getLocalize('game.display');

const Text = () => {
  const ref = useRef(null);

  useLayoutEffect(() => {
    const { offsetTime } = appState;
    const animationDelay = 4.3 - offsetTime;
    if (animationDelay <= 0) return;

    ref.current.style.animationDelay = `${animationDelay}s`;
  }, []);

  return (
    <div ref={ref} className={styles.container}>
      <Typography variant="bold" size="18">
        {l('loading')}
      </Typography>
    </div>
  );
};

export default Text;
