import { useLayoutEffect, useContext } from 'react';
import { localize } from '@utils/locales';
import usePrevious from '@hooks/usePrevious';
import Toggle from '@components/toggle';
import Typography from '@components/typography';
import { FreeBetContext } from '../.././../../../context/free-bet';
import styles from './index.module.css';

const Bet = ({ checked, setAutoBet }) => {
  const { isFreeBet } = useContext(FreeBetContext);
  const prevIsFreeBet = usePrevious(isFreeBet);

  useLayoutEffect(() => {
    if (prevIsFreeBet === isFreeBet) return;
    setAutoBet(false);
  }, [isFreeBet, prevIsFreeBet, setAutoBet]);

  const onBetChange = (checked) => {
    setAutoBet(checked);
  };

  return (
    <div className={styles.container}>
      <span className={styles.label}>
        <Typography variant="regular" size="12">
          {localize('game.bet.autoBet')}
        </Typography>
      </span>
      <Toggle checked={checked} onChange={onBetChange} />
    </div>
  );
};

export default Bet;
