import { classNames } from '@utils/helpers';
import * as Icons from './icons';
import styles from './index.module.css';

// size = xSmall, small, medium, large, custom
// variant = primary, secondary, tertiary, success, destructive, custom

const Icon = ({ icon, variant = 'primary', size = 'medium', disabled }) => {
  const Svg = Icons[icon];

  if (!Svg) return null;

  const className = classNames([styles.container, styles[variant], styles[size], disabled && styles.disabled]);

  return (
    <span className={className}>
      <Svg />
    </span>
  );
};

export default Icon;
