import { CHANNELS } from '@utils/enums';
import { socket } from '@utils/socket';
import fallback from './en';

let keys = fallback;

const getTranslations = () =>
  new Promise((resolve) => {
    socket.once(CHANNELS.TRANSLATIONS, (res) => resolve(res));
    setTimeout(() => resolve(null), 1000);
  });

export const setLocalize = async () => {
  const translations = await getTranslations();
  if (translations) keys = translations;
};

export const localize = (key) => keys[key] || key;

export const getLocalize = (globalKey) => (key) => localize(globalKey + '.' + key);
