import { createContext, useEffect } from 'react';
import { PHASES } from '@utils/enums';
import { appState } from '@utils/store';
import usePhase from '@hooks/socket/usePhase';

export const PhaseContext = createContext(null);

const PhaseProvider = ({ initPhase, children }) => {
  const phase = usePhase(initPhase);

  useEffect(() => {
    if (phase === PHASES.GAME_ENDED) appState.playedRounds += 1;
  }, [phase]);

  return <PhaseContext.Provider value={phase}>{children}</PhaseContext.Provider>;
};

export default PhaseProvider;
