import resources from './resources';

const preloadImage = (src) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = resolve;
    image.onerror = reject;
    image.src = src;
  });

const preload = async () => {
  const promises = resources.map((src) => preloadImage(src));
  return Promise.allSettled(promises);
};

export default preload;
