import Copy from '@components/copy';
import IconContainer from '@components/icon-container';
import ListItem from '@components/list-item';
import Typography from '@components/typography';
import Player from './parts/player';
import styles from './index.module.css';

const autoGeneratedHash = ({ clientHash, l }) => (
  <div className={styles.item}>
    <ListItem
      variant="ghost"
      prefix={<IconContainer variant="secondary" icon="UserLock" size="medium" />}
      label={l('client-auto.label')}
      description={l('server-auto.description')}
    />

    <div className={styles.itemCopy}>
      <div className={styles.ellipsis}>
        <Typography variant="regular" size="14">
          {clientHash}
        </Typography>
      </div>

      <Copy value={clientHash} />
    </div>
  </div>
);

export const clientHash = ({ details, l }) => {
  const { seeds, clientHash } = details;
  if (!seeds.length) return autoGeneratedHash({ clientHash, l });

  const playersRender = seeds.map(({ username, seed }, index) => (
    <Player key={seed} id={seed} label={`${l('player.title')} N${index + 1}`} player={username} hash={seed} />
  ));

  return (
    <div className={styles.item}>
      <ListItem
        variant="ghost"
        prefix={<IconContainer variant="secondary" icon="UserLock" size="medium" />}
        label={l('client.label')}
        description={l('client.description')}
      />
      <div className={styles.players}>{playersRender}</div>
    </div>
  );
};
