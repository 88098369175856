import { classNames } from '@utils/helpers';
import styles from './index.module.css';

// variant: bold | regular
// size: 10 | 12 | 14 | 16 | 18 | 20 | 24 | 28 | 32

const Typography = ({ variant, size, underline, children }) => {
  const className = classNames([
    styles.container,
    styles[variant],
    styles['size-' + size],
    underline && styles.underline,
  ]);

  return <span className={className}>{children}</span>;
};

export default Typography;
