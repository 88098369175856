import { rangeRandom } from '@utils/helpers';
import styles from './index.module.css';

const defaultDelay = 5;

const Comet = ({ delay }) => {
  const top = rangeRandom(0, 0.1) * 100 + '%';
  const left = rangeRandom(0.5, 1) * 100 + '%';

  const sumDelay = defaultDelay + delay + 's';

  return <div className={styles.container} style={{ top, left, animationDelay: sumDelay }} />;
};

export default Comet;
