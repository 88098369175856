import { getLocalize } from '@utils/locales';
import Button from '@components/button';
import Table from '../table';
import useFreeBets from './useFreeBets';
import styles from './index.module.css';

const l = getLocalize('game.freeBets.history');

const History = () => {
  const { freeBets, loading, loadMore } = useFreeBets();

  return (
    <div className={styles.container}>
      <Table items={freeBets} />

      {loadMore && (
        <div>
          <Button variant="neutral" loading={loading} onClick={loadMore}>
            {l('loadMore')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default History;
