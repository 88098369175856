import { classNames } from '@utils/helpers';
import Typography from '@components/typography';
import styles from './index.module.css';

// variant = default | magic

const Chip = ({ variant = 'default', onClick, children }) => {
  const className = classNames([styles.container, styles[variant]]);

  return (
    <button className={className} onClick={onClick}>
      <Typography variant="bold" size="14">
        {children}
      </Typography>
    </button>
  );
};

export default Chip;
