import { useContext } from 'react';
import { PHASES } from '@utils/enums';
import { PhaseContext } from '@context/phase';
import Fly from './parts/fly';
import Loading from './parts/loading';
import styles from './index.module.css';

const Display = () => {
  const phase = useContext(PhaseContext);

  const exploded = phase === PHASES.GAME_ENDED;

  const display = phase === PHASES.BETTING ? <Loading /> : <Fly exploded={exploded} />;

  return (
    <div id="display" className={styles.container}>
      <div className={styles.wrapper}>{display}</div>
    </div>
  );
};

export default Display;
