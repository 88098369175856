import Typography from '@components/typography';
import styles from './index.module.css';

const Chip = ({ children }) => (
  <div className={styles.container}>
    <Typography variant="bold" size="12">
      {children}
    </Typography>
  </div>
);

export default Chip;
