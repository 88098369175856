import { useState, useEffect } from 'react';
import { getLocalize } from '@utils/locales';
import ButtonIcon from '@components/button-icon';
import { copyToClipboard } from '@components/copy/helpers';
import Icon from '@components/icon';
import Typography from '@components/typography';
import styles from './index.module.css';

const l = getLocalize('component.copy');

const Copy = ({ value }) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      const timeout = setTimeout(() => setCopied(false), 3000);
      return () => clearTimeout(timeout);
    }
  }, [copied]);

  const onClick = () => {
    copyToClipboard(value)
      .then(() => setCopied(true))
      .catch((error) => console.log(error));
  };

  const contentRender = copied ? (
    <div className={styles.copied}>
      <Icon icon="Check" size="xSmall" />
      <Typography variant="regular" size="12">
        {l('copied')}
      </Typography>
    </div>
  ) : (
    <ButtonIcon variant="ghost" icon="Copy" onClick={onClick} />
  );

  return <div className={styles.container}>{contentRender}</div>;
};

export default Copy;
