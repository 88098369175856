import { getLocalize } from '@utils/locales';
import Avatar from '@components/avatar';
import Copy from '@components/copy';
import Typography from '@components/typography';
import { randomAvatar } from '@game/parts/menu/items/profile/helpers';
import styles from './index.module.css';

const l = getLocalize('game.history.details.player');

const Player = ({ id, label, player, hash }) => (
  <div className={styles.container}>
    <Typography variant="regular" size="14">
      <span className={styles.label}>{label}</span>
    </Typography>

    <Typography variant="regular" size="14">
      <span className={styles.label}>{l('seed')}</span>
    </Typography>

    <div className={styles.player}>
      <Avatar name={randomAvatar(id)} size="medium" />
      <Typography variant="regular" size="14">
        <div className={styles.value}>{player}</div>
      </Typography>
    </div>

    <div className={styles.value}>
      <Typography variant="regular" size="14">
        {hash}
      </Typography>

      <Copy value={hash} />
    </div>
  </div>
);

export default Player;
