import { useEffect, useState, useLayoutEffect } from 'react';
import { CHANNELS, PHASES } from '@utils/enums';
import { socket } from '@utils/socket';
import { appState } from '@utils/store';

const setGamePhase = (setState) => {
  const GAME_UPDATE = ({ phase }) => {
    if (phase === PHASES.MULTIPLIER_UPDATE) phase = PHASES.GAME_STARTED;
    if (phase === PHASES.WAITING) phase = PHASES.GAME_ENDED;
    setState(phase);
  };

  socket.on(CHANNELS.GAME_UPDATE, GAME_UPDATE);

  return () => {
    socket.off(CHANNELS.GAME_UPDATE, GAME_UPDATE);
  };
};

const usePhase = (initPhase) => {
  const [phase, setPhase] = useState(initPhase); // BETTING, GAME_STARTED, GAME_ENDED

  useLayoutEffect(() => {
    if (initPhase === phase) return;
    appState.offsetTime = 0;
  }, [initPhase, phase]);

  useEffect(() => setGamePhase(setPhase), []);

  return phase;
};

export default usePhase;
