import { Howler } from 'howler';
import { GLOBAL_ERRORS } from '@utils/enums';
import { listenSocketError } from '@game/helpers';

export const onGlobalError = (setState) => {
  const CLIENT_ERRORS = ({ reason }) => {
    if (!GLOBAL_ERRORS.includes(reason)) return;
    Howler.mute(true);
    setState({ hasError: true, reason });
  };

  return listenSocketError(CLIENT_ERRORS);
};
