import { useRef, useEffect } from 'react';
import { classNames } from '@utils/helpers';
import styles from './index.module.css';

const Scroll = ({ hideShadow = false, overScroll, children }) => {
  const bottomRef = useRef();
  const className = classNames([styles.container, overScroll && styles.overscroll]);

  useEffect(() => {
    const bottom = bottomRef.current;
    if (!bottom) return;

    const observer = new IntersectionObserver((entries) => {
      const { isIntersecting } = entries[0];
      bottom.classList.toggle(styles.hideShadow, isIntersecting);
    });

    observer.observe(bottom);

    return () => observer.disconnect();
  }, []);

  return (
    <div className={className}>
      <div className={styles.children}>
        {children}

        {!hideShadow && (
          <div className={styles.bottom} ref={bottomRef}>
            <div className={styles.shadow}></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Scroll;
