export const appState = {
  roundId: null,
  session: null,
  initPhase: null,
  modal: null,
  snackbar: null,
  offsetTime: 0,
  refresh: null,
  balance: null,
  placedBets: { tab: 'current' },
  playedRounds: 0,

  myBets: { items: [], total: 0 },
  freeBets: { items: [], total: 0 },
  freeBet: { suggestions: [] },
  bet: {
    left: { betValue: null, freeBetValue: null, autoBet: null, autoCashOut: null },
    right: { betValue: null, freeBetValue: null, autoBet: null, autoCashOut: null },
  },

  settings: {},
};

export const resetAppState = () => {
  appState.roundId = null;
  appState.session = null;
  appState.initPhase = null;
  appState.bet = { left: {}, right: {} };
};

export const appResources = {
  audios: {},
};
