import { getLocalize } from '@utils/locales';
import Button from '@components/button';
import Typography from '@components/typography';
import { ERRORS } from './helpers';
import styles from './index.module.css';

const l = getLocalize('component.error.global');

const Global = ({ reason }) => {
  const key = ERRORS[reason] || 'default';

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <img className={styles.image} src="/images/plane.svg" alt="Logo" />

        <div className={styles.text}>
          <Typography variant="bold" size="24">
            {l(key + '.title')}
          </Typography>

          <Typography variant="regular" size="18">
            {l(key + '.description')}
          </Typography>
        </div>

        <a href="https://google.com" target="_blank">
          <Button variant="neutral">{l('home')}</Button>
        </a>
      </div>
    </div>
  );
};

export default Global;
