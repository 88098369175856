import { useContext } from 'react';
import { SettingsContext } from '@context/settings';
import Animation from './parts/animation';
import Comets from './parts/comets';
import Multiplier from './parts/multiplier';

const Fly = ({ exploded }) => {
  const { settings } = useContext(SettingsContext);
  const { animation, dark } = settings;

  const animationRender = animation && (
    <>
      {dark && <Comets />}
      <Animation dark={dark} exploded={exploded} />
    </>
  );

  return (
    <>
      {animationRender}
      <Multiplier exploded={exploded} />
    </>
  );
};

export default Fly;
