import { useCallback, useRef } from 'react';
import { Graphics, useTick } from '@pixi/react';
import { rangeRandom } from '@utils/helpers';
import { calculatedCoordinate } from '../../helpers';

const defaultSize = { min: 1, max: 3 };

const Stars = ({ resolution, animate, starCount = 800, size = defaultSize, color = 0xffffff, speed = 1 }) => {
  const graphicsRef = useRef(null);

  const { width, height } = resolution;

  useTick((delta) => {
    if (!animate) return;
    graphicsRef.current.rotation -= 0.0003 * delta * speed;
  });

  const draw = useCallback(
    (graphic) => {
      graphic.clear();

      for (let i = 0; i < starCount; i++) {
        graphic.beginFill(color, Math.random());
        const { x, y } = calculatedCoordinate({ height, width });
        const rectSize = rangeRandom(size.min, size.max);
        graphic.drawRect(x, y, rectSize, rectSize);
        graphic.endFill();
      }
    },
    [starCount, color, height, width, size.min, size.max]
  );

  return <Graphics ref={graphicsRef} draw={draw} />;
};

export default Stars;
