import Typography from '@components/typography';
import { format } from './helpers';
import styles from './index.module.css';

const Item = ({ img, description, index, children }) => (
  <div className={styles.container}>
    <img src={img} className={styles.img} alt="instruction" />

    <div className={styles.index}>
      <Typography variant="bold" size="14">
        {format(index + 1)}
      </Typography>
    </div>

    <div className={styles.description}>
      <Typography variant="regular" size="14">
        {description}
      </Typography>
      {children}
    </div>
  </div>
);

export default Item;
