import { getLocalize } from '@utils/locales';
import Button from '@components/button';
import Empty from '@components/empty';
import Skeleton from '@components/skeleton';
import Typography from '@components/typography';
import Item from './parts/item';
import useMyBets from './useMyBets';
import styles from './index.module.css';

const l = getLocalize('game.placedBets.my');

const My = () => {
  const { bets, loading, loadMore } = useMyBets();

  if (!bets) return <Skeleton />;

  if (!bets.length) return <Empty label={l('empty')} />;

  return (
    <div className={styles.container}>
      <div className={styles.table}>
        <div className={styles.header}>
          <Typography variant="regular" size="12">
            {l('date')}
          </Typography>
          <Typography variant="regular" size="12">
            {l('bet')}
          </Typography>
          <Typography variant="regular" size="12">
            {l('win')}
          </Typography>
        </div>

        <div className={styles.bets}>
          {bets.map((item) => (
            <Item key={item._id} {...item} />
          ))}
        </div>
      </div>

      {loadMore && (
        <div>
          <Button variant="neutral" loading={loading} onClick={loadMore}>
            {l('loadMore')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default My;
