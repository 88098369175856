import { CHANNELS, ACTIONS } from '@utils/enums';
import { socket } from '@utils/socket';
import { appState } from '@utils/store';

export const onBet = ({ betId, action, betValue, maxMultiplier, isFreeBet }) => {
  if (action === ACTIONS.BET) {
    const method = maxMultiplier ? 'automatic' : 'manual';
    const params = { betAmount: betValue, betNominal: betValue, type: isFreeBet ? 'freebet' : 'normal' };
    sendBet({ betId, method, maxMultiplier, ...params });
  } else if (action === ACTIONS.CANCEL) sendClearBet({ betId });
  else if (action === ACTIONS.CASH_OUT) sendCashOut({ betId });
};

export const sendBet = (bet) => {
  if (!bet.maxMultiplier) bet.maxMultiplier = 0;
  socket.emit(CHANNELS.PLACE_BET, { roundId: appState.roundId, bet });
};

export const sendClearBet = ({ betId }) => {
  socket.emit(CHANNELS.CLEAR_BET, { roundId: appState.roundId, betId });
};

export const sendCashOut = ({ betId }) => {
  // TODO: remove
  const timestamp = Date.now();
  socket.emit(CHANNELS.MANUAL_CASH_OUT, { roundId: appState.roundId, betId, timestamp });
};
