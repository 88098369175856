import { useEffect, useState, useCallback } from 'react';
import { hideAddressBar, timeoutPromise } from '@utils/helpers';
import { preloadResource, preloadSocket } from '@utils/preload';
import { appState } from '@utils/store';
import SettingsProvider from '@context/settings';
import Preload from '@components/preload';
import Game from './game';

hideAddressBar();

const preloadPromise = async () => {
  await Promise.all([preloadResource(), timeoutPromise(1000)]);
  await preloadSocket();
};

const setTheme = () => {
  const dataTheme = localStorage.getItem('menu_dark') === 'false' ? 'light' : 'dark';
  document.documentElement.setAttribute('data-theme', dataTheme);
};

const App = () => {
  const [preload, setPreload] = useState(false);

  useEffect(setTheme, []);

  useEffect(() => {
    preloadPromise().then(() => setPreload(true));
  }, []);

  appState.refresh = useCallback(async () => {
    setPreload(false);
    await preloadSocket().then(() => setPreload(true));
  }, []);

  if (!preload) return <Preload />;

  return (
    <SettingsProvider>
      <Game initPhase={appState.initPhase} />
    </SettingsProvider>
  );
};

export default App;
