import { rangeRandom } from '@utils/helpers';

export const calculatedCoordinate = ({ width, height }) => {
  const size = Math.max(width, height);
  const diagonal = size * rangeRandom(0.2, 1.2);
  const angle = Math.random() * Math.PI * 2;

  const x = Math.cos(angle) * diagonal;
  const y = Math.sin(angle) * diagonal;

  return { x, y };
};
