import { useEffect, useState } from 'react';
import { classNames } from '@utils/helpers';
import styles from './index.module.css';

const Toggle = ({ checked, onChange }) => {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const onClick = () => {
    onChange?.(!isChecked);
    setIsChecked((prev) => !prev);
  };

  const className = classNames([styles.container, isChecked && styles.checked]);

  return (
    <div className={className} onClick={onClick}>
      <div className={styles.slider}></div>
    </div>
  );
};

export default Toggle;
