const Shine = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={218} height={56} fill="none">
    <path
      fill="url(#a)"
      d="m.248 85.768 91.846 6.396 3.324-.01L217.6 100.13l-2.565-26.17-80.177 6.452 74.732-22.227-23.765-29.437-57.64 42.541 41.025-60.043-49.346-15.911-8.715 76-15.732-76L52.896 5.909l42.473 68.379-61.64-52.601-17.807 22.07 76.14 36.773L5.177 64.977l-4.93 20.79Z"
      opacity={0.9}
    />
    <defs>
      <radialGradient
        id="a"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="matrix(.13176 47.99152 -108.6566 .2983 108.813 52.436)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" stopOpacity={0.5} />
        <stop offset={1} stopColor="#fff" stopOpacity={0} />
      </radialGradient>
    </defs>
  </svg>
);

export default Shine;
