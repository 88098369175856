const images = [
  '/images/logo.svg',
  '/images/provably/dark.png',
  '/images/provably/light.png',
  '/images/plane.svg',
  '/images/studio.svg',

  '/cloud/bg_1.svg',
  '/cloud/m_1.svg',
  '/cloud/m_2.svg',
  '/cloud/m_3.svg',
  '/cloud/f_1.svg',
  '/cloud/f_2.svg',
];

const avatars = [
  '/avatars/1.jpg',
  '/avatars/2.jpg',
  '/avatars/3.jpg',
  '/avatars/4.jpg',
  '/avatars/5.jpg',
  '/avatars/6.jpg',
  '/avatars/7.jpg',
  '/avatars/8.jpg',
  '/avatars/9.jpg',
  '/avatars/10.jpg',
  '/avatars/11.jpg',
  '/avatars/12.jpg',
  '/avatars/13.jpg',
  '/avatars/14.jpg',
  '/avatars/15.jpg',
];

const resources = [...images, ...avatars];

export default resources;
