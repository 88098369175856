import { useState, useEffect, useRef, useContext } from 'react';
import { classNames } from '@utils/helpers';
import { localize, getLocalize } from '@utils/locales';
import { appState } from '@utils/store';
import { SettingsContext } from '@context/settings';
import ButtonIcon from '@components/button-icon';
import Typography from '@components/typography';
import Chip from './components/chip';
import Details from './parts/details';
import useHistory from './useHistory';
import styles from './index.module.css';

const l = getLocalize('game.history');

const colors = ['white', 'pink', 'green'];

const getColor = (multiplier) => {
  if (multiplier < 2) return colors[0];
  if (multiplier < 10) return colors[1];
  return colors[2];
};

const History = () => {
  const history = useHistory();
  const firstId = useRef(null);

  const { settings } = useContext(SettingsContext);
  const { dark } = settings;

  const [open, setOpen] = useState(false);

  useEffect(() => {
    const id = history[0]?._id;
    if (id) requestAnimationFrame(() => (firstId.current = id));
  }, [history]);

  const onClick = (roundId) => {
    const element = <Details roundId={roundId} dark={dark} />;
    appState.modal.open({ element, label: localize('game.history.details.title') });
  };

  const onOpenClick = () => setOpen(!open);

  const historyElement = history.map(({ _id, multiplierCrash }, index) => (
    <Chip key={index + _id} variant={getColor(multiplierCrash)} onClick={() => onClick(_id)}>
      {multiplierCrash.toFixed(2)}
    </Chip>
  ));

  const className = classNames([styles.wrapper, open && 'historyOpen ' + styles.open]);

  const animate = firstId.current && firstId.current !== history[0]?._id && !open;
  const itemsClassName = classNames([styles.items, animate && styles.animate]);

  return (
    <div className={className}>
      <div className={styles.container}>
        {open && (
          <div className={styles.header}>
            <Typography variant="bold" size="14">
              {l('title')}
            </Typography>
            <ButtonIcon onClick={onOpenClick} icon="Close" />
          </div>
        )}

        <div className={styles.itemsWrapper}>
          <div className={itemsClassName}>{historyElement}</div>
        </div>

        {!open && (
          <span className={styles.button}>
            <ButtonIcon iconVariant="custom" icon="ClockFilled" onClick={onOpenClick} />
          </span>
        )}
      </div>
    </div>
  );
};

export default History;
