import { classNames } from '@utils/helpers';
import { getLocalize } from '@utils/locales';
import Accordion from '@components/accordion';
import Icon from '@components/icon';
import Typography from '@components/typography';
import { formatDate } from './helpers';
import styles from './index.module.css';

const l = getLocalize('game.freeBets.history');

const Item = ({ item }) => {
  const headerClassName = classNames([styles.header, item.isActive && styles.active]);

  const header = (
    <div className={headerClassName}>
      <div className={styles.count}>
        <div className={styles.icon}>
          <Icon icon="Free" variant="custom" size="custom" />
        </div>
        <Typography variant="bold" size="16">
          {item.count - item.usage} / {item.count}
        </Typography>
      </div>
      <Typography variant="bold" size="16">
        {item.betNominal.toFixed(2)}
      </Typography>
      <Typography variant="bold" size="16">
        {item.totalWin.toFixed(2)}
      </Typography>
    </div>
  );

  return (
    <Accordion variant="dark" header={header}>
      <div className={styles.content}>
        <div className={styles.description}>
          <Typography variant="regular" size="14">
            {item.description}
          </Typography>
        </div>
        <div className={styles.item}>
          <Typography variant="regular" size="14">
            {l('granted')}
          </Typography>
          <Typography variant="regular" size="14">
            {formatDate(item.createdAt)}
          </Typography>
        </div>
        <div className={styles.item}>
          <Typography variant="regular" size="14">
            {l('minCashout')}
          </Typography>
          <Typography variant="regular" size="14">
            {item.minMultiplier}x
          </Typography>
        </div>
        <div className={styles.item}>
          <Typography variant="regular" size="14">
            {l('date')}
          </Typography>
          <Typography variant="regular" size="14">
            {formatDate(item.validityDate)}
          </Typography>
        </div>
      </div>
    </Accordion>
  );
};

export default Item;
