import { useState } from 'react';
import Current from './parts/current';
import Previous from './parts/previous';

const Round = () => {
  const [tab, setTab] = useState('current');

  const TabContent = tab === 'previous' ? Previous : Current;

  return <TabContent setTab={setTab} />;
};

export default Round;
