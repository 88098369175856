import { useState } from 'react';
import useDeviceType from '@hooks/useDeviceType';
import ButtonIcon from '@components/button-icon';
import Item from './parts/item';
import styles from './index.module.css';

const Slider = ({ items }) => {
  const deviceType = useDeviceType();
  const [activeIndex, setActiveIndex] = useState(0);

  if (deviceType !== 'mobile') {
    return (
      <div className={styles.webContainer}>
        {items.map((item, index) => (
          <Item key={index} {...item} index={index} />
        ))}
      </div>
    );
  }

  const onSlide = (direction) => setActiveIndex((prev) => prev + direction);

  const item = items[activeIndex];

  return (
    <div className={styles.container}>
      <Item {...item} index={activeIndex}>
        <div className={styles.arrow}>
          <ButtonIcon
            variant="ghost"
            icon="ChevronLeft"
            size="large"
            disabled={activeIndex === 0}
            onClick={() => onSlide(-1)}
          />

          <ButtonIcon
            variant="ghost"
            icon="ChevronRight"
            size="large"
            disabled={activeIndex === items.length - 1}
            onClick={() => onSlide(1)}
          />
        </div>
      </Item>
    </div>
  );
};

export default Slider;
