const locales = {
  'component.input.apply': 'Apply',
  'component.copy.copied': 'Copied',
  'component.error.disconnect.title': 'Checking network connection',
  'component.error.disconnect.description': 'Trying to reconnect...',
  'component.error.connectFailed.title': 'Connection failed',
  'component.error.connectFailed.description': 'Please check your network connection',
  'component.error.connectFailed.home': 'Back to home',
  'component.error.global.home': 'Back to home',
  'component.error.global.default.title': 'Something went wrong',
  'component.error.global.default.description': 'Please try again later',
  'component.error.global.general.title': 'Server error',
  'component.error.global.general.description': 'Please try again later',
  'component.error.global.authentication.title': 'Authentication failed',
  'component.error.global.authentication.description': 'Please login again',
  'component.error.global.switched.title': 'Session switched',
  'component.error.global.switched.description': 'Please login again',
  'game.bet.bet': 'BET',
  'game.bet.freeBet': 'FREE BET',
  'game.bet.cancel': 'CANCEL',
  'game.bet.waiting': 'WAITING',
  'game.bet.cashOut': 'CASH OUT',
  'game.bet.autoBet': 'Auto bet',
  'game.bet.freeBetCheck': 'Free ',
  'game.bet.autoCashOut': 'Auto cash out',
  'game.bet.error.balance': 'Insufficient balance',
  'game.bet.error.freeBet': 'Free bet expired',
  'game.bet.error.global': 'Something went wrong',
  'game.avatar.title': 'Avatar',
  'game.avatar.save': 'Change',
  'game.display.loading': 'Waiting For Next Round',
  'game.display.flew': 'FLEW AWAY!',
  'game.history.title': 'Round history',
  'game.history.details.title': 'Seed details',
  'game.history.details.date': 'Date',
  'game.history.details.player.title': 'Player',
  'game.history.details.player.seed': 'Seed',
  'game.history.details.server.label': 'Server Seed',
  'game.history.details.server.description': 'Generated on our side',
  'game.history.details.client.label': 'Client Seed',
  'game.history.details.client.description': 'Generated on player side',
  'game.history.details.combined.label': 'Combined SHA512 Hash',
  'game.history.details.combined.description':
    'Above seeds combined and converted to SHA512 Hash. This is your game result',
  'game.history.details.combined.hex': 'Hex',
  'game.history.details.combined.decimal': 'Decimal',
  'game.history.details.combined.result': 'Result',
  'game.history.details.provablyFair': 'Meaning of Provably Fair',
  'game.placedBets.tab.current': 'Current',
  'game.placedBets.tab.my': 'My',
  'game.placedBets.round.current.total': 'Total Bets',
  'game.placedBets.round.current.previous': 'Previous Round',
  'game.placedBets.round.previous.total': 'Total Bets',
  'game.placedBets.round.previous.previous': 'Previous Round',
  'game.placedBets.round.table.empty': 'No bets placed',
  'game.placedBets.round.table.player': 'Player',
  'game.placedBets.round.table.bet': 'Bet',
  'game.placedBets.round.table.win': 'Win',
  'game.placedBets.my.empty': 'No bets placed',
  'game.placedBets.my.date': 'Date',
  'game.placedBets.my.bet': 'Bet',
  'game.placedBets.my.win': 'Win',
  'game.placedBets.my.loadMore': 'Load more',
  'game.provablyFair.title': 'Provably Fair',
  'game.provablyFair.what.title': 'Provably Fair - 100 % Fair game',
  'game.provablyFair.what.description':
    'Aviator uses cryptographic technology called Provably Fair. This technology guarantees 100% fairness of game result. With this technology, it is impossible for any third party to interfere in game process.',
  'game.provablyFair.how.title': 'How it works',
  'game.provablyFair.how.short.title': 'In short',
  'game.provablyFair.how.short.description':
    'The result of each round is generated with the help of round players and is fully transparent. This way, it is impossible for anyone to manipulate game output.',
  'game.provablyFair.how.long.title': 'More information',
  'game.provablyFair.how.long.description':
    'Round result is generated from four independent participants of the round: game operator and first 3 betters of the round. Operator is generating server seed (random 16 symbols). Client seed is generated on the side of each player and when round starts first 3 betters are participating in generating round result. When round starts, game merges server seed with three client seeds. From merged symbols is generated SHA512 hash, and from this hash - game result.',
  'game.provablyFair.reliability.title': 'How to check',
  'game.provablyFair.reliability.description': [
    'You can check fairness of each round from game history, by clicking on green icon.',
    'In opened window, you will see server seed, 3 pair of players seeds, combined hash and round result.',
    'If you want to participate in round result generation, make sure you are between first 3 players who make bet in that round.',
  ],
  'game.footer.provablyFair': 'Provably Fair Game',
  'game.menu.sound': 'Sound',
  'game.menu.music': 'Music',
  'game.menu.animation': 'Animation',
  'game.menu.freeBets': 'Free Bets',
  'game.menu.myBets': 'My Bets',
  'game.menu.rules': 'Rules',
  'game.menu.limits': 'Limits',
  'game.menu.home': 'Back to home',
  'game.freeBets.title': 'Free Bets',
  'game.freeBets.tab.active': 'Active',
  'game.freeBets.tab.history': 'History',
  'game.freeBets.table.count': 'Count',
  'game.freeBets.table.bet': 'Bet',
  'game.freeBets.table.cashout': 'Cash Out',
  'game.freeBets.table.empty': 'No free bets available',
  'game.freeBets.history.granted': 'Granted',
  'game.freeBets.history.minCashout': 'Min Cashout',
  'game.freeBets.history.date': 'Date',
  'game.freeBets.history.loadMore': 'Load more',
  'game.myBets.title': 'My Bets',
  'game.myBets.table.empty': 'No bets placed',
  'game.rules.title': 'Game rules',
  'game.rules.how.title': 'How to play',
  'game.rules.how.description': 'There are 2 steps, which you should follow to win in the game',
  'game.rules.slider.1': 'Make a desired amount of bet before plane takes off',
  'game.rules.slider.2': 'Cash out before the plane disappears and win X times more',
  'game.rules.additional.title': 'More details',
  'game.rules.accordion.0.title': 'Bet & Cash Out',
  'game.rules.accordion.0.description': [
    'Select an amount and press the "Bet" button to make a bet.',
    'You can cancel the bet by pressing the "Cancel" button if the round has not started yet.',
    'Adjust the bet size using the "+" and "-" buttons to change the bet amount. Alternatively, you can select the bet size using the preset values or enter a value manually.',
    'You can make two bets simultaneously, by adding a second bet panel. To add a second bet panel, press the plus icon, which is located in the top right corner of the first bet panel.',
    'Press the "Cash Out" button to cash out your winnings. Your win is your bet multiplied by the Cash Out multiplier.',
    "Your bet is lost, if you didn't cash out before the plane flies away.",
  ],
  'game.rules.accordion.1.title': 'Auto Play & Auto Cash Out',
  'game.rules.accordion.1.description': [
    'Before starting Auto Play, select the bet size you want to play with. How to do this is described in the Bet & Cash Out section.',
    'Auto Play is activated from the "Auto bet" toggle in the Bet Panel. After activation, bets will be placed automatically, but for Cash Out, you should press the "Cash Out" button in each round. If you want the bet to cash out automatically, then use the "Auto Cash Out" option',
    'Auto Cash Out is available from the "Auto Cash Out" toggle on the Bet panel. After activation, your bet will be automatically cashed out when it reaches the multiplier entered',
  ],
  'game.rules.accordion.2.title': 'Free bets',
  'game.rules.accordion.2.description': [
    'You can check the status of Free Bets, from the Game Menu > Free Bets. Free Bets are awarded by the operator. If you have an active Free bet you will see the Free bet toggle in the Bet panel;',
  ],
  'game.rules.accordion.3.title': 'Randomization',
  'game.rules.accordion.3.description': [
    'The multiplier for each round is generated by a "Provably Fair" algorithm and is completely transparent, and 100% fair.',
  ],
  'game.rules.accordion.4.title': 'Game Menu',
  'game.rules.accordion.4.description': [
    'Access the game menu by tapping the menu button in the top right corner of the screen.',
    'Toggle the "Sound" switch to turn the game sounds on or off.',
    'Toggle the "Music" switch to turn the background music on or off.',
    'Toggle the "Animation" switch to turn the airplane animation on or off.',
    'Press the "Limits" button to view the limits for minimum and maximum bets and the maximum win limit.',
    'Press the "My Bets " button to view the history of your bets',
    'Open "Game Rules" to read the detailed rules of the game',
  ],
  'game.limits.title': 'Limits',
  'game.limits.min': 'Min bet',
  'game.limits.max': 'Max bet',
  'game.limits.win': 'Max win',
  'game.win.snackbar.label': 'Cashed out!',
  'game.win.snackbar.win': 'Win',
};

export default locales;
