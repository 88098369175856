import { useEffect, useState } from 'react';
import { CHANNELS, PHASES } from '@utils/enums';
import { socket } from '@utils/socket';

const useMultiplier = () => {
  const [multiplier, setMultiplier] = useState(1);

  useEffect(() => {
    const MULTIPLIER_UPDATE = (multiplier) => setMultiplier(multiplier);

    socket.on(CHANNELS.MULTIPLIER_UPDATE, MULTIPLIER_UPDATE);

    const GAME_UPDATE = ({ phase }) => {
      if (phase === PHASES.BETTING) setMultiplier(1);
    };

    socket.on(CHANNELS.GAME_UPDATE, GAME_UPDATE);

    return () => {
      socket.off(CHANNELS.MULTIPLIER_UPDATE, MULTIPLIER_UPDATE);
      socket.off(CHANNELS.GAME_UPDATE, GAME_UPDATE);
    };
  }, []);

  return multiplier;
};

export default useMultiplier;
