import styles from './index.module.css';

const Clouds = () => {
  return (
    <div className={styles.container}>
      <img name="bg_1" className={styles.background} src="/cloud/bg_1.svg" alt="cloud" />

      <img name="m_1" className={styles.middle} src="/cloud/m_1.svg" alt="cloud" />
      <img name="m_2" className={styles.middle} src="/cloud/m_2.svg" alt="cloud" />
      <img name="m_3" className={styles.middle} src="/cloud/m_3.svg" alt="cloud" />

      <img name="f_1" className={styles.front} src="/cloud/f_1.svg" alt="cloud" />
      <img name="f_2" className={styles.front} src="/cloud/f_2.svg" alt="cloud" />
    </div>
  );
};

export default Clouds;
