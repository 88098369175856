import { useEffect, useRef } from 'react';
import { classNames } from '@utils/helpers';
import useResolution from '@hooks/useResolution';
import Typography from '@components/typography';
import styles from './index.module.css';

const Tabs = ({ tabs, tab, setTab }) => {
  const activeRef = useRef(null);
  const tabRef = useRef(null);
  const resolution = useResolution();

  const onClick = (name) => {
    if (tab === name) return;
    setTab(name);
  };

  useEffect(() => {
    requestAnimationFrame(() => {
      const activeTab = tabRef.current;
      if (!activeTab) return;
      const { clientWidth, offsetLeft } = activeTab;

      activeRef.current.style.width = `${clientWidth}px`;
      activeRef.current.style.transform = `translateX(${offsetLeft}px)`;
    });
  }, [tab, tabs, resolution]);

  const getRef = (name) => (name === tab ? tabRef : null);

  const itemClassName = (name) => classNames([styles.item, name === tab && styles.activeItem]);

  return (
    <div className={styles.container}>
      <div className={styles.active} ref={activeRef}></div>

      {tabs.map(({ name, label }) => (
        <div key={name} className={itemClassName(name)} ref={getRef(name)} onClick={() => onClick(name)}>
          <Typography variant="regular" size="12">
            {label}
          </Typography>
        </div>
      ))}
    </div>
  );
};

export default Tabs;
