const UserLock = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} fill="none">
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M18.77 19.999h-2.365a1.173 1.173 0 0 1-1.174-1.174v-1.327c0-.648.525-1.174 1.174-1.174h2.364c.648 0 1.174.526 1.174 1.174v1.327c0 .65-.526 1.174-1.174 1.174Z"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M18.997 16.35v-.874a1.412 1.412 0 0 0-2.822-.006v.88M11.843 14.695c-3.32 0-6.15.503-6.15 2.511 0 2.01 2.814 2.529 6.15 2.529"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M15.773 7.93a3.93 3.93 0 1 1-7.858-.001 3.93 3.93 0 0 1 7.858 0Z"
      clipRule="evenodd"
    />
  </svg>
);

export default UserLock;
