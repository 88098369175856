import Scroll from '@components/scroll';
import My from '@game/parts/placed-bets/parts/my';

const Content = () => {
  return (
    <Scroll>
      <My />
    </Scroll>
  );
};

export default Content;
