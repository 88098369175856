import { setLocalize } from '@utils/locales';
import audioPreload from './parts/audios';
import fonsPreload from './parts/fonts';
import imagesPreload from './parts/images';

const preload = () => {
  const promises = [setLocalize(), imagesPreload(), audioPreload(), fonsPreload()];
  return Promise.all(promises);
};

export default preload;
