import { useEffect, useState } from 'react';
import styles from './index.module.css';

const Dropdown = ({ children, button, closeButton }) => {
  const [open, setOpen] = useState(false);

  const buttonChild = open ? closeButton || button : button;

  const handleOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (!open) return;

    const close = () => setOpen(false);

    const closeKey = (event) => {
      if (event.key === 'Escape') close();
    };

    requestAnimationFrame(() => {
      window.addEventListener('click', close);
      window.addEventListener('keydown', closeKey);
    });

    return () => {
      window.removeEventListener('click', close);
      window.addEventListener('keydown', closeKey);
    };
  }, [open]);

  return (
    <div className={styles.wrapper}>
      <span onClick={handleOpen}>{buttonChild}</span>

      {open && <div className={styles.container}>{children}</div>}
    </div>
  );
};

export default Dropdown;
