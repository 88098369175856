import { useContext, useState } from 'react';
import { SettingsContext } from '@context/settings';
import IconContainer from '@components/icon-container';
import ListItem from '@components/list-item';
import Toggle from '@components/toggle';

const CheckItem = ({ name, label, icon }) => {
  const { settings, setSetting } = useContext(SettingsContext);

  const [checked, setChecked] = useState(settings[name]);

  const onClick = (event) => {
    event.stopPropagation();
    setChecked(!checked);
    setSetting(name, !checked);
  };

  const suffix = <Toggle checked={checked} />;

  return (
    <ListItem
      variant="ghost"
      label={label}
      prefix={<IconContainer icon={icon} variant="secondary" size="medium" />}
      suffix={suffix}
      onClick={onClick}
    />
  );
};

export default CheckItem;
