import { useRef } from 'react';
import useDeviceType from '@hooks/useDeviceType';
import Scroll from '@components/scroll';
import Bets from '../bets';
import Display from '../display';
import Footer from '../footer';
import Header from '../header';
import History from '../history';
import PlacedBets from '../placed-bets';
import styles from './index.module.css';

const Responsive = () => {
  const deviceType = useDeviceType();
  const bottomRef = useRef();

  if (!deviceType) return null;

  if (deviceType === 'desktop') {
    return (
      <div className={styles.desktopContainer}>
        <div className={styles.desktopAside}>
          <Header />
          <PlacedBets />
          <Footer />
        </div>
        <div className={styles.container}>
          <div className={styles.game}>
            <History />
            <Display />
          </div>

          <Bets />
        </div>
      </div>
    );
  }

  if (deviceType === 'landscape') {
    return (
      <div className={styles.landscapeContainer}>
        <Header />
        <div className={styles.container}>
          <div className={styles.landscapeDisplay}>
            <History />

            <div className={styles.game}>
              <Display />
            </div>
          </div>

          <Bets />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Header sticky bottomRef={bottomRef} />
      <Scroll hideShadow>
        <div className={styles.page}>
          <div className={styles.game}>
            <History />
            <Display />
          </div>

          <Bets />
        </div>
        <div className={styles.page}>
          <PlacedBets overScroll />
          <Footer />
        </div>
        <div className={styles.bottom} ref={bottomRef} />
      </Scroll>
    </div>
  );
};

export default Responsive;
