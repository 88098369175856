export const canFullScreen = () => {
  const element = document.getElementById('root');
  return element.requestFullscreen;
};

export const openFullscreen = () => {
  const element = document.getElementById('root');
  const requestFullscreen = element.requestFullscreen;
  requestFullscreen?.call(element);
};

export const exitFullscreen = () => {
  const exitFullscreen = document.exitFullscreen;
  exitFullscreen?.call(document);
};
