import { useContext, useEffect, useState } from 'react';
import { CHANNELS, PHASES } from '@utils/enums';
import { socket } from '@utils/socket';
import { appState } from '@utils/store';
import { PhaseContext } from '@context/phase';

const setGameBalance = (setBalance) => {
  if (!appState.balance) socket.emit(CHANNELS.BALANCE_UPDATE);

  const BALANCE_UPDATE = ({ balance }) => {
    setBalance(balance);
    appState.balance = balance;
  };

  socket.on(CHANNELS.BALANCE_UPDATE, BALANCE_UPDATE);

  return () => {
    socket.off(CHANNELS.BALANCE_UPDATE, BALANCE_UPDATE);
  };
};

const useBalance = () => {
  const [balance, setBalance] = useState(appState.balance || 0);

  const phase = useContext(PhaseContext);

  useEffect(() => setGameBalance(setBalance), []);

  useEffect(() => {
    if (phase === PHASES.BETTING) socket.emit(CHANNELS.BALANCE_UPDATE);
  }, [phase]);

  return balance?.toFixed(2);
};

export default useBalance;
