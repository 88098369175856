import { useState, useContext } from 'react';
import ActionProvider from './context/action';
import FreeBetProvider, { FreeBetContext } from './context/free-bet';
import BetButton from './parts/bet-button';
import BetInput from './parts/bet-input';
import FreeBetInput from './parts/free/bet-input';
import styles from './index.module.css';

const Bet = ({ betId }) => {
  const { isFreeBet } = useContext(FreeBetContext);

  const [betValue, setBetValue] = useState(0);

  const InputElement = isFreeBet ? FreeBetInput : BetInput;

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <InputElement betId={betId} betValue={betValue} setBetValue={setBetValue} />
        <BetButton betId={betId} betValue={betValue} />
      </div>
    </div>
  );
};

const BetWrapper = ({ betId }) => (
  <FreeBetProvider betId={betId}>
    <ActionProvider betId={betId}>
      <Bet betId={betId} />
    </ActionProvider>
  </FreeBetProvider>
);

export default BetWrapper;
