import { socket } from '@utils/socket';
import { appState, resetAppState } from '@utils/store';
import ConnectFailed from '@components/error/components/connect-failed';
import Disconnect from '../components/disconnect';

const onConnect = () => {
  console.log('onConnect');
  let isReconnect = false;

  const CONNECT = async () => {
    if (!isReconnect) {
      isReconnect = true;
      return;
    }

    resetAppState();
    await appState?.refresh();
    appState.modal.close();
  };

  socket.on('connect', CONNECT);

  return () => socket.off('connect', CONNECT);
};

const onOnline = () => {
  const ONLINE = () => {
    socket.connect();
  };

  window.addEventListener('online', ONLINE);

  return () => window.removeEventListener('online', ONLINE);
};

const onDisconnect = () => {
  const DISCONNECT = () => {
    const element = <Disconnect />;
    appState.modal.open({ element, short: true, unclosable: true, force: true });
  };

  socket.on('disconnect', DISCONNECT);

  return () => socket.off('disconnect', DISCONNECT);
};

const onReconnectFailed = () => {
  const RECONNECT_FAILED = () => {
    const element = <ConnectFailed />;
    appState.modal.open({ element, short: true, unclosable: true, force: true });
  };

  socket.io.on('reconnect_failed', RECONNECT_FAILED);

  return () => {
    socket.io.off('reconnect_failed', RECONNECT_FAILED);
  };
};

socket.io.on('error', (error) => {
  console.log('error', { error });
});

export const onNetworkError = () => [onDisconnect(), onReconnectFailed(), onConnect(), onOnline()];
