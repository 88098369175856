import { createContext, useMemo, useEffect, useState, useContext } from 'react';
import { PHASES, CHANNELS } from '@utils/enums';
import { socket } from '@utils/socket';
import { appState } from '@utils/store';
import { PhaseContext } from '@context/phase';
import useSuggestions from './useSuggestions';

export const FreeBetContext = createContext(null);

const FreeBetProvider = ({ betId, children }) => {
  const betState = useMemo(() => appState.bet[betId], [betId]);
  const suggestions = useSuggestions();
  const phase = useContext(PhaseContext);

  const [isFreeBet, setIsFreeBet] = useState(betState.type === 'freebet');

  useEffect(() => {
    betState.type = isFreeBet ? 'freebet' : 'normal';
  }, [betState, isFreeBet]);

  const leftSuggestions = useMemo(() => suggestions.filter(({ usage, total }) => usage < total), [suggestions]);

  const showCheck = isFreeBet || !!leftSuggestions.length;

  useEffect(() => {
    if (phase !== PHASES.GAME_ENDED) return;
    if (!leftSuggestions.length) setIsFreeBet(false);
  }, [phase, leftSuggestions.length]);

  useEffect(() => {
    if (phase === PHASES.GAME_ENDED) socket.emit(CHANNELS.PLAYER_FREEBETS);
  }, [phase]);

  return (
    <FreeBetContext.Provider value={{ isFreeBet, showCheck, setIsFreeBet, suggestions, leftSuggestions }}>
      {children}
    </FreeBetContext.Provider>
  );
};

export default FreeBetProvider;
