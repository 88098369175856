import { memo, useContext, useMemo } from 'react';
import { Container, Stage } from '@pixi/react';
import { SettingsContext } from '@context/settings';
import useResolution from '@hooks/useResolution';
import Stars from './parts/stars';

const stageOptions = {
  antialias: false,
  resolution: 1,
  interactiveChildren: false,
  backgroundAlpha: 0,
};

const Dark = () => {
  const resolution = useResolution();

  const { settings } = useContext(SettingsContext);

  const stageRender = useMemo(() => {
    if (!resolution) return null;

    const { width, height } = resolution;

    return (
      <Stage options={stageOptions} width={width} height={height}>
        <Container position={{ x: width, y: height }}>
          <Stars animate={settings.animation} resolution={resolution} size={{ min: 1.5, max: 2 }} />
          <Stars animate={settings.animation} resolution={resolution} size={{ min: 1, max: 1.5 }} speed={0.5} />
        </Container>
      </Stage>
    );
  }, [resolution, settings.animation]);

  if (!resolution) return null;

  return stageRender;
};

export default memo(Dark);
