import { useContext } from 'react';
import { SettingsContext } from '@context/settings';
import Dark from './parts/dark';
import Light from './parts/light';
import styles from './index.module.css';

const Space = () => {
  const { settings } = useContext(SettingsContext);

  const { dark } = settings;

  return <div className={styles.wrapper}>{dark ? <Dark /> : <Light />}</div>;
};

export default Space;
