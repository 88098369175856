import { CHANNELS } from '@utils/enums';
import { socket } from '@utils/socket';

export const setActiveBets = (setFreeBets) => {
  socket.emit(CHANNELS.PLAYER_FREEBETS_ACTIVE, { skip: 0, limit: 100 });

  socket.once(CHANNELS.PLAYER_FREEBETS_ACTIVE, ({ docs }) => setFreeBets(docs));

  return () => {
    socket.off(CHANNELS.PLAYER_FREEBETS_ACTIVE);
  };
};
