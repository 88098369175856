import { classNames } from '@utils/helpers';
import styles from './index.module.css';

const Skeleton = ({ small }) => {
  const className = classNames([styles.container, small && styles.small]);

  return (
    <div className={className}>
      <div className={styles.loader}></div>
    </div>
  );
};

export default Skeleton;
