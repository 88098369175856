import { useContext, useEffect, useState } from 'react';
import { CHANNELS, PHASES } from '@utils/enums';
import { socket } from '@utils/socket';
import { appState } from '@utils/store';
import { PhaseContext } from '@context/phase';

const setRoundBets = (setBets) => {
  socket.emit(CHANNELS.ROUND_BETS, { roundId: appState.roundId });

  const ROUNDS_BETS = ({ bets }) => setBets(bets);

  socket.on(CHANNELS.ROUND_BETS, ROUNDS_BETS);

  const PLACE_BET = ({ playerId, bet: { betId, betAmount } }) => {
    setBets((prev) => [...prev, { betId, playerId, betAmount }]);
  };

  socket.on(CHANNELS.PLACE_BET, PLACE_BET);

  const CLEAR_BET = ({ playerId, betId }) => {
    setBets((prev) => prev.filter((bet) => !(bet.playerId === playerId && bet.betId === betId)));
  };

  socket.on(CHANNELS.CLEAR_BET, CLEAR_BET);

  const ROUND_CASHOUTS = ({ playerId, betId, multiplier, win }) => {
    setBets((prev) => {
      const bet = prev?.find((bet) => bet.betId === betId && bet.playerId === playerId);
      if (!bet) return prev;

      bet.multiplier = multiplier;
      bet.win = win;

      return [...prev];
    });
  };

  socket.on(CHANNELS.ROUND_CASHOUTS, ROUND_CASHOUTS);

  return () => {
    socket.off(CHANNELS.ROUND_BETS, ROUNDS_BETS);
    socket.off(CHANNELS.PLACE_BET, PLACE_BET);
    socket.off(CHANNELS.ROUND_CASHOUTS, ROUND_CASHOUTS);
  };
};

const useRoundBets = () => {
  const phase = useContext(PhaseContext);
  const [bets, setBets] = useState(null);

  useEffect(() => setRoundBets(setBets), []);

  useEffect(() => {
    if (phase === PHASES.BETTING) setBets([]);
  }, [phase]);

  return bets?.sort((a, b) => b.betAmount - a.betAmount);
};

export default useRoundBets;
