export const isTouchDevice = 'ontouchstart' in window;

const round = (value) => Math.round(value * 100) / 100;

export const validateBet = ({ value, min, max }) => {
  value = Number(value) || 0;
  if (!value) return min;
  if (value > max) return max;
  if (value < min) return min;
  return round(value);
};

export const classNames = (classNames) => classNames.filter(Boolean).join(' ');

export const isBetOutRange = (value, min, max) => {
  const number = Number(value) || 0;
  return number < min || number > max;
};

export const rangeRandom = (min, max) => Math.random() * (max - min) + min;

export const rangeNatural = (min, max) => Math.round(rangeRandom(min, max));

export const generateRandomString = () => btoa(Math.random().toString()).substring(10, 15);

export const hideAddressBar = () => {
  const hide = () => requestAnimationFrame(() => window.scrollTo(0, 500));
  hide();

  window.addEventListener('orientationchange', hide);
  return () => {
    window.removeEventListener('orientationchange', hide);
  };
};

export const clamp = (min, value, max) => Math.min(Math.max(value, min), max);

export const timeoutPromise = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
