import { Container } from '@pixi/react';
import Plane from './parts/plane';
import Trace from './parts/trace';
import useCoordinates from './parts/useCoordinates';

const Flight = ({ dark, resolution, exploded }) => {
  const coordinates = useCoordinates({ resolution, exploded, dark });

  return (
    <Container x={20} y={0}>
      {!exploded && dark && <Trace resolution={resolution} coordinates={coordinates} />}
      <Plane coordinates={coordinates} dark={dark} />
    </Container>
  );
};

export default Flight;
