import { clamp } from '@utils/helpers';

export const getPlaneWidth = () => Math.max(window.innerWidth / 10, 100);
export const getPlaneHeight = ({ height, width }) => getPlaneWidth() * (height / (2 * width));

const horizontalMs = () => 600 * 2;

const getMaxX = (width) => width - getPlaneWidth();
const getMaxY = (height, dark) => height * (dark ? 0.5 : 0.6);

const getSpeed = (coordinate, distance, time) => {
  const speed = clamp(0.02, 1 - coordinate / distance, 0.5);
  return distance / (time / speed);
};

let yStep = 0;
let timeElapsed = 0;

const getYSpeed = (initPlace, height, tickArgs) => {
  const [, { deltaMS }] = tickArgs;
  const accelerationTime = 2000 / deltaMS;
  const maxSpeed = 2 / (3400 / deltaMS);

  const speed = timeElapsed < accelerationTime ? (maxSpeed / accelerationTime) * timeElapsed : maxSpeed;
  const newY = Math.sin(yStep) * height * 0.2;

  timeElapsed += 1;
  yStep += speed;

  return initPlace + newY;
};

export const calculateXY = ({ coordinates, resolution, dark, tickArgs }) => {
  const { width, height } = resolution;
  let { x, y } = coordinates;

  const [, { deltaMS }] = tickArgs;

  const maxX = getMaxX(width);
  const maxY = getMaxY(height, dark);

  const planeWidth = getPlaneWidth();
  const planeHeight = getPlaneHeight({ width, height });

  const xDistance = maxX + planeWidth;
  const yDistance = height + planeHeight - maxY;

  if (x < maxX) {
    const ms = horizontalMs();
    const xSpeed = getSpeed(x + planeWidth, xDistance, ms / deltaMS);
    const ySpeed = getSpeed(height + planeHeight - y, yDistance, ms / deltaMS);

    const newX = Math.min(x + xSpeed, maxX);
    const newY = Math.max(y - ySpeed, maxY);

    timeElapsed = 0;
    yStep = 0;
    return { x: newX, y: newY };
  }

  if (!dark) return { x, y };

  const newY = getYSpeed(maxY, height, tickArgs);

  return { x, y: newY };
};

export const animateExplode = ({ coordinates, resolution, tickArgs }) => {
  const { x, y } = coordinates;
  const { width, height } = resolution;
  const [delta] = tickArgs;

  if (y < 0 && x > width) return coordinates;

  const newX = x + (width / 15) * delta;
  const newY = y - (height / 50) * delta;

  return { x: newX, y: newY };
};
