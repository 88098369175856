const Backspace = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} fill="none">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.792 13.53a.75.75 0 1 1-1.061 1.062l-1.525-1.525-1.525 1.525a.749.749 0 1 1-1.06-1.061l1.525-1.524-1.525-1.526a.749.749 0 1 1 1.06-1.06l1.525 1.524L15.73 9.42a.75.75 0 0 1 1.06 1.06l-1.524 1.526 1.525 1.524Zm1.536-8.82H9.202c-.747 0-1.453.318-1.936.872L3.135 10.31a2.572 2.572 0 0 0-.001 3.379l4.132 4.73a2.567 2.567 0 0 0 1.936.87h9.126a3.174 3.174 0 0 0 3.17-3.17V7.88a3.174 3.174 0 0 0-3.17-3.17Z"
      clipRule="evenodd"
    />
  </svg>
);

export default Backspace;
