import { AnimatedSprite, Container } from '@pixi/react';
import { getPlaneWidth } from '../../helpers';

const darkImages = ['/canvas/plane/d_1.png', '/canvas/plane/d_2.png'];
const lightImages = ['/canvas/plane/l_1.png', '/canvas/plane/l_2.png'];

const Plane = ({ coordinates, dark }) => {
  const { x } = coordinates;

  const planeWidth = getPlaneWidth();

  if (x === -planeWidth) return null;

  const images = dark ? darkImages : lightImages;

  return (
    <Container position={coordinates} scale={planeWidth / 200}>
      <AnimatedSprite isPlaying images={images} animationSpeed={0.35} anchor={{ x: 0.01, y: 0.82 }} />
    </Container>
  );
};

export default Plane;
