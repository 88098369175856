import Bet from './parts/bet';
import styles from './index.module.css';

const Bets = () => {
  return (
    <div className={styles.container}>
      <Bet betId="left" />
      <Bet betId="right" />
    </div>
  );
};

export default Bets;
