import IconContainer from '@components/icon-container';
import Typography from '@components/typography';
import styles from './index.module.css';

const Empty = ({ label }) => (
  <div className={styles.container}>
    <IconContainer variant="secondary" icon="Clock" size="large" />
    <Typography variant="regular" size="14">
      {label}
    </Typography>
  </div>
);

export default Empty;
