import { classNames } from '@utils/helpers';
import Icon from '@components/icon';
import Typography from '@components/typography';
import styles from './index.module.css';

const Modal = ({ label, description, open, setOpen, children }) => {
  const className = classNames([styles.container, open && styles.open]);

  const onClose = () => {
    setOpen(false);
  };

  return (
    <div className={className}>
      <div className={styles.header}>
        <Typography variant="bold" size="18">
          {label}
        </Typography>

        <span className={styles.description}>
          <Typography variant="regular" size="14">
            {description}
          </Typography>
        </span>
        <div className={styles.close} onClick={onClose}>
          <Icon icon="Close" />
        </div>
      </div>
      {children}
    </div>
  );
};

export default Modal;
