import { PHASES } from '@utils/enums';
import { appResources, appState } from '@utils/store';

export const getSounds = () => ({
  background: appResources.audios.background,
  [PHASES.BETTING]: appResources.audios.betting,
  [PHASES.GAME_ENDED]: appResources.audios.crash,
});

export const playSound = (sound = {}, offsetTime = 0, loop = false) => {
  const currentTime = offsetTime + appState.offsetTime;

  sound.seek(currentTime);
  sound.offsetTime = currentTime;
  sound.loop(loop);

  if (sound.playing()) return;
  sound.customStart();
};
