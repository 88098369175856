import { useState } from 'react';
import { localize } from '@utils/locales';
import { appState } from '@utils/store';
import Avatar from '@components/avatar';
import ButtonIcon from '@components/button-icon';
import Typography from '@components/typography';
import Item from '../../parts/item';
import { savedAvatar } from './helpers';
import ChangeAvatar from './parts/changeAvatar';

const Profile = () => {
  const [avatar, setAvatar] = useState(savedAvatar());

  const onClick = () => {
    const element = <ChangeAvatar avatar={avatar} setAvatar={setAvatar} />;
    appState.modal.open({ element, label: localize('game.avatar.title') });
  };

  const label = (
    <Typography variant="bold" size="16">
      {appState.session.player.username}
    </Typography>
  );

  return (
    <Item
      label={label}
      prefix={<Avatar name={avatar} />}
      suffix={<ButtonIcon variant="ghost" icon="Edit" size="medium" />}
      onClick={onClick}
    />
  );
};

export default Profile;
