import { appState } from '@utils/store';

export const avatarNames = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15'];

const generateSameRandom = (id, max) => {
  let hash = 0;

  for (let i = 0; i < id.length; i++) {
    hash = (hash << 5) - hash + id.charCodeAt(i);
    hash |= 0; // Convert to 32bit integer
  }

  return Math.abs(hash) % max;
};

export const randomAvatar = (id) => avatarNames[generateSameRandom(id, avatarNames.length)];

export const savedAvatar = () => {
  let saved = localStorage.getItem('avatar');
  if (saved && !avatarNames.includes(saved)) saved = null;
  const avatar = saved || randomAvatar(appState.session.player.playerId);
  if (!saved) localStorage.setItem('avatar', avatar);
  return avatar;
};
