import { classNames } from '@utils/helpers';
import Icon from '@components/icon';
import styles from './index.module.css';

const iconSize = {
  small: 'xSmall',
  medium: 'small',
  large: 'medium',
};

// variant = primary, secondary, danger, success
// size = small, medium, large

const IconContainer = ({ icon, variant = 'primary', size = 'medium' }) => {
  const className = classNames([styles.container, styles[variant], styles[size]]);

  return (
    <div className={className}>
      <Icon icon={icon} size={iconSize[size]} />
    </div>
  );
};

export default IconContainer;
