import { classNames } from '@utils/helpers';
import Typography from '@components/typography';
import styles from './index.module.css';

// variant: primary, ghost
const ListItem = ({ variant = 'primary', hasPadding, prefix, label, description, suffix, onClick }) => {
  const className = classNames([
    styles.container,
    styles[variant],
    hasPadding && styles.hasPadding,
    onClick && styles.clickable,
  ]);

  return (
    <div className={className} onClick={onClick || null}>
      {prefix && prefix}

      <div className={styles.text}>
        <Typography variant="bold" size="14">
          {label}
        </Typography>
        {description && (
          <Typography variant="regular" size="12">
            {description}
          </Typography>
        )}
      </div>

      {suffix && <div className={styles.suffix}>{suffix}</div>}
    </div>
  );
};

export default ListItem;
