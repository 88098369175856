const PlaneFilled = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} fill="none">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M21.655 8.09a2.334 2.334 0 0 0-1.23-1.482c-1.176-.58-2.577-.198-3.126-.008-1.086.376-2.136.819-3.23 1.243a160.155 160.155 0 0 0-3.602-3.085c-.82-.69-1.666-.941-2.516-.746-.46.1-.893.259-1.312.413-.196.072-.394.144-.599.213a.741.741 0 0 0-.439.385.688.688 0 0 0-.009.57l2.07 4.818c-.718.289-1.599.649-2.428.987L4 11.9l-.306-.71a.76.76 0 0 0-.977-.394.71.71 0 0 0-.412.938c.091.213.45 1.044.544 1.258l.051.114.008.017c.008.02.017.037.026.055.891 1.86 2.368 2.848 4.155 2.848.675 0 1.395-.142 2.145-.43 3.553-1.372 7.116-2.808 10.266-4.077 1.358-.558 2.613-1.87 2.156-3.43ZM19.043 19.325H4.954c-.414 0-.75.322-.75.72 0 .397.336.72.75.72h14.09c.413 0 .75-.323.75-.72 0-.398-.337-.72-.75-.72Z"
      clipRule="evenodd"
    />
  </svg>
);
export default PlaneFilled;
