const Music = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} fill="none">
    <g fill="currentColor" stroke="currentColor" strokeWidth={0.2}>
      <path d="m19.688 4.774-.057-.082-.082.057-1.232.856-.082.057.057.082.428.616a10.732 10.732 0 0 1 0 12.28l-.428.616-.057.082.082.057 1.231.856.082.057.058-.082.428-.616-.082-.057.082.057a12.431 12.431 0 0 0 0-14.22l-.428-.616Z" />
      <path d="m17.062 7.422-.05-.087-.086.05-1.302.745-.087.05.05.087.373.65a7.19 7.19 0 0 1 0 7.167l.087.05-.087-.05-.373.65-.05.087.086.05 1.301.746.087.05.05-.087.373-.65a8.89 8.89 0 0 0 0-8.857l-.372-.651ZM11.415 4.137a.6.6 0 0 0-.393.147L7.178 7.616a.4.4 0 0 1-.262.098H2.752a.6.6 0 0 0-.6.6v.25c-.003 2.624-.003 5.248 0 7.873v.25a.6.6 0 0 0 .6.6h4.164a.4.4 0 0 1 .262.097l3.844 3.332a.6.6 0 0 0 .393.147h1.119a.6.6 0 0 0 .6-.6V4.737a.6.6 0 0 0-.6-.6h-1.12Zm-.643 2.614a.4.4 0 0 1 .662.302v10.894a.4.4 0 0 1-.662.303l-2.903-2.517a.6.6 0 0 0-.393-.147H4.25a.4.4 0 0 1-.4-.4V9.815c0-.221.18-.4.4-.4h3.226a.6.6 0 0 0 .393-.147l2.903-2.516Z" />
    </g>
  </svg>
);

export default Music;
