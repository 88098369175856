import { classNames } from '@utils/helpers';
import Skeleton from '@components/skeleton';
import styles from './index.module.css';

// variant: 'primary' | 'neutral'
// size: 'small' | 'medium'

const Button = ({ variant = 'primary', size = 'medium', loading, children, onClick }) => {
  const className = classNames([styles.container, styles[variant], styles[size], loading && styles.loading]);

  return (
    <div className={className}>
      <button className={styles.button} onClick={onClick}>
        {loading ? <Skeleton small /> : children}
      </button>
    </div>
  );
};

export default Button;
