import lottie from 'lottie-web';
import { appState } from '@utils/store';
import LoadingLottie from './data.json';

export const createAnimation = (container) => {
  const animation = lottie.loadAnimation({
    animationData: LoadingLottie,
    container,
    autoplay: true,
    loop: false,
    renderer: 'canvas',
  });

  requestAnimationFrame(() => {
    const { offsetTime } = appState;
    if (offsetTime) animation.goToAndPlay(offsetTime * 1000);
  });

  return () => {
    appState.offsetTime = animation.currentRawFrame / animation.frameRate + 0.1;
    animation.destroy();
  };
};
