import { useContext } from 'react';
import { FreeBetContext } from '../../../../../context/free-bet';
import Item from './parts/item';
import styles from './index.module.css';

const Suggestions = ({ onClick }) => {
  const { leftSuggestions } = useContext(FreeBetContext);

  const buttons = leftSuggestions.map((suggestion) => (
    <Item key={suggestion.betNominal} {...suggestion} onClick={onClick} />
  ));

  return <div className={styles.container}>{buttons}</div>;
};

export default Suggestions;
