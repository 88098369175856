import { useContext, useEffect, useState } from 'react';
import { CHANNELS, PHASES } from '@utils/enums';
import { socket } from '@utils/socket';
import { appState } from '@utils/store';
import { PhaseContext } from '@context/phase';

const setGameHistory = (setHistory) => {
  if (!appState.history) socket.emit(CHANNELS.ROUNDS_HISTORY);

  const ROUNDS_HISTORY = ({ rounds }) => {
    appState.history = rounds;
    setHistory(rounds);
  };

  socket.on(CHANNELS.ROUNDS_HISTORY, ROUNDS_HISTORY);

  return () => {
    socket.off(CHANNELS.ROUNDS_HISTORY, ROUNDS_HISTORY);
  };
};

const useHistory = () => {
  const [history, setHistory] = useState(appState.history || []);

  const phase = useContext(PhaseContext);

  useEffect(() => setGameHistory(setHistory), []);

  useEffect(() => {
    if (phase === PHASES.GAME_ENDED) {
      const limit = Math.min(30 + appState.playedRounds, 60);
      socket.emit(CHANNELS.ROUNDS_HISTORY, { skip: 0, limit });
    }
  }, [phase]);

  return history;
};

export default useHistory;
