import { classNames } from '@utils/helpers';
import Icon from '@components/icon';
import styles from './index.module.css';

// variant: 'fill' | 'ghost'
// size: 'small' | 'medium' | 'large'

const iconSize = {
  small: 'xSmall',
  medium: 'xSmall',
  large: 'small',
};

const ButtonIcon = ({ variant = 'fill', size = 'medium', disabled, icon, iconVariant = 'primary', onClick }) => {
  const className = classNames([styles.container, styles[variant], styles[size], disabled && styles.disabled]);

  return (
    <button className={className} onClick={onClick}>
      <Icon variant={iconVariant} size={iconSize[size]} icon={icon} />
    </button>
  );
};

export default ButtonIcon;
