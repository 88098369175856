import { createContext, useEffect, useMemo, useState, useContext } from 'react';
import { ACTIONS } from '@utils/enums';
import { appState } from '@utils/store';
import usePrevious from '@hooks/usePrevious';
import { FreeBetContext } from '../free-bet';

export const ActionContext = createContext(null);

const ActionProvider = ({ betId, children }) => {
  const betState = useMemo(() => appState.bet[betId], [betId]);
  const [action, setAction] = useState(betState.action || ACTIONS.BET);

  const { isFreeBet } = useContext(FreeBetContext);
  const prevIsFreeBet = usePrevious(isFreeBet);

  useEffect(() => {
    betState.action = action;
  }, [betState, action]);

  useEffect(() => {
    if (prevIsFreeBet === isFreeBet) return;
    setAction(ACTIONS.BET);
  }, [prevIsFreeBet, isFreeBet]);

  return <ActionContext.Provider value={{ action, setAction }}>{children}</ActionContext.Provider>;
};

export default ActionProvider;
