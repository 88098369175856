export const CHANNELS = {
  TRANSLATIONS: 'TRANSLATIONS',
  GAME_UPDATE: 'GAME_UPDATE',
  MULTIPLIER_UPDATE: 'MULTIPLIER_UPDATE',
  ONGOING_ROUND: 'ONGOING_ROUND',
  AUTO_CASH_OUT: 'AUTO_CASH_OUT',
  JOIN_ROUND: 'JOIN_ROUND',
  PLACE_BET: 'PLACE_BET',
  CLEAR_BET: 'CLEAR_BET',
  MANUAL_CASH_OUT: 'MANUAL_CASH_OUT',
  SESSION: 'SESSION',
  BALANCE_UPDATE: 'BALANCE_UPDATE',
  ROUND_CASHOUTS: 'ROUND_CASHOUTS',
  ROUNDS_HISTORY: 'ROUNDS_HISTORY',
  CLIENT_ERRORS: 'CLIENT_ERRORS',
  ROUND_BETS: 'ROUND_BETS',
  PLAYER_BETS: 'PLAYER_BETS',
  CURRENT_BETS: 'CURRENT_BETS',
  PREVIOUS_ROUND_BETS: 'PREVIOUS_ROUND_BETS',
  ONGOING_ROUND_TIME: 'ONGOING_ROUND_TIME',
  PLACE_BET_LOCAL: 'PLACE_BET_LOCAL',
  CLEAR_BET_LOCAL: 'CLEAR_BET_LOCAL',

  PLAYER_FREEBETS: 'PLAYER_FREEBETS',
  PLAYER_FREEBETS_ACTIVE: 'PLAYER_FREEBETS_ACTIVE',
  PLAYER_FREEBETS_PASSIVE: 'PLAYER_FREEBETS_PASSIVE',
};

export const PHASES = {
  BETTING: 'BETTING',
  GAME_STARTED: 'GAME_STARTED',
  GAME_ENDED: 'GAME_ENDED',
  MULTIPLIER_UPDATE: 'MULTIPLIER_UPDATE',
  WAITING: 'WAITING',
};

export const ERRORS = {
  EXPECTED_BETTING_PHASE: 'EXPECTED_BETTING_PHASE',
  FUND_WITHDRAW_FAILED: 'FUND_WITHDRAW_FAILED',
  PROVIDER_API_FAILURE: 'PROVIDER_API_FAILURE',
  SWITCHED_SESSION: 'SWITCHED_SESSION',
  AUTHENTICATION_FAILED: 'AUTHENTICATION_FAILED',
  GENERAL_SERVER_ERROR: 'GENERAL_SERVER_ERROR',
  INTEGRATION_TIMED_OUT: 'INTEGRATION_TIMED_OUT',
  AUTHENTICATION_TOKEN_EXPIRED: 'AUTHENTICATION_TOKEN_EXPIRED',
  INVALID_BET_AMOUNT: 'INVALID_BET_AMOUNT',
  INVALID_BET: 'INVALID_BET',

  // FreeBets
  FAILED_TO_CLEAR_BET: 'FAILED_TO_CLEAR_BET',
  FREEBETS_EXPIRED: 'FREEBETS_EXPIRED',
  INVALID_BET_NOMINAL: 'INVALID_BET_NOMINAL',
};

export const ACTIONS = {
  BET: 'BET',
  CANCEL: 'CANCEL',
  CASH_OUT: 'CASH_OUT',
  WAITING: 'WAITING',
  FREE: 'FREE',
};

export const BET_ERRORS = [
  ERRORS.FUND_WITHDRAW_FAILED,
  ERRORS.EXPECTED_BETTING_PHASE,
  ERRORS.INVALID_BET,
  ERRORS.PROVIDER_API_FAILURE,
  ERRORS.INTEGRATION_TIMED_OUT,
  ERRORS.INVALID_BET_NOMINAL,
  ERRORS.FREEBETS_EXPIRED,
  ERRORS.INVALID_BET_AMOUNT,
];

export const GLOBAL_ERRORS = [
  ERRORS.SWITCHED_SESSION,
  ERRORS.AUTHENTICATION_FAILED,
  ERRORS.AUTHENTICATION_TOKEN_EXPIRED,
  ERRORS.GENERAL_SERVER_ERROR,
];
