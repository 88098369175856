import { useEffect } from 'react';
import '@utils/preload';
import PhaseProvider from '@context/phase';
import { onWin, joinNextRound } from './helpers';
import Responsive from './parts/responsive';
import Sound from './parts/sound';
import Space from './parts/space';

const Game = ({ initPhase }) => {
  useEffect(onWin, []);

  useEffect(joinNextRound, []);

  return (
    <PhaseProvider initPhase={initPhase}>
      <Space />
      <Responsive />
      <Sound />
    </PhaseProvider>
  );
};

export default Game;
