import { memo } from 'react';
import { Stage } from '@pixi/react';
import useResolution from '@hooks/useResolution';
import Bullets from './parts/bullets';
import Flight from './parts/flight';
import styles from './index.module.css';

const stageOptions = {
  backgroundAlpha: 0,
  resolution: window.devicePixelRatio || 1,
};

const flightResolution = (resolution) => ({ width: resolution.width - 20, height: resolution.height - 20 });

const Animation = ({ dark, exploded }) => {
  const resolution = useResolution('display');

  if (!resolution) return null;

  return (
    <Stage className={styles.container} {...resolution} options={stageOptions}>
      <Flight dark={dark} resolution={flightResolution(resolution)} exploded={exploded} />
      <Bullets dark={dark} resolution={resolution} exploded={exploded} />
    </Stage>
  );
};

export default memo(Animation);
