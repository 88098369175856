import { getSession, joinOngoingRound, syncBets, phaseTime } from './helpers';

const preload = async () => {
  await getSession();

  await joinOngoingRound();

  await syncBets();

  await phaseTime();
};

export default preload;
