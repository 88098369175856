import { isTouchDevice } from '@utils/helpers';
import Native from './native';
import Touch from './touch';

const Input = ({ label, suffix, min, max, disabled, value, setValue }) => {
  const Element = isTouchDevice ? Touch : Native;

  return (
    <Element label={label} suffix={suffix} min={min} max={max} disabled={disabled} value={value} setValue={setValue} />
  );
};

export default Input;
