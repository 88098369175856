import { Component } from 'react';
import Global from './components/global';
import { socketError } from './helpers';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, reason: '' };
  }

  static getDerivedStateFromError() {
    return { hasError: true, reason: '' };
  }

  componentDidMount() {
    this.socketListener = socketError(this.setState.bind(this));
  }

  componentWillUnmount() {
    this.socketListener();
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) return <Global reason={this.state.reason} />;
    return this.props.children;
  }
}

export default ErrorBoundary;
