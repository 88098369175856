import { useEffect, useState, useContext } from 'react';
import { CHANNELS } from '@utils/enums';
import { socket } from '@utils/socket';
import { PhaseContext } from '@context/phase';

const getPreviousBets = (setDetails) => {
  socket.emit(CHANNELS.PREVIOUS_ROUND_BETS);

  const PREVIOUS_ROUND_BETS = ({ bets, roundDetails: { multiplier } }) => {
    setDetails({ bets, multiplier });
  };
  socket.on(CHANNELS.PREVIOUS_ROUND_BETS, PREVIOUS_ROUND_BETS);

  return () => {
    socket.off(CHANNELS.PREVIOUS_ROUND_BETS, PREVIOUS_ROUND_BETS);
  };
};

export const usePreviousBets = () => {
  const [details, setDetails] = useState(null);

  const phase = useContext(PhaseContext);

  useEffect(() => getPreviousBets(setDetails), []);

  useEffect(() => {
    if (phase === 'BETTING') return getPreviousBets(setDetails);
  }, [phase]);

  const { multiplier, totalBets, bets } = details || {};
  return { multiplier, totalBets, bets };
};
