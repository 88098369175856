import { useState } from 'react';
import { classNames } from '@utils/helpers';
import ButtonIcon from '@components/button-icon';
import Typography from '@components/typography';
import styles from './index.module.css';

const Accordion = ({ variant = 'default', label, header, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const className = classNames([styles.container, styles[variant], isOpen && styles.open]);

  const onClick = () => setIsOpen((prev) => !prev);

  return (
    <div className={className}>
      <div className={styles.header} onClick={onClick}>
        {header || (
          <Typography variant="bold" size="16">
            {label}
          </Typography>
        )}

        <ButtonIcon icon={isOpen ? 'ChevronUp' : 'ChevronDown'} />
      </div>

      <div className={styles.contentWrapper}>
        <div className={styles.content}>
          <div className={styles.children}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Accordion;
