import { classNames } from '@utils/helpers';
import Typography from '@components/typography';
import styles from './index.module.css';

const Snackbar = ({ type, message, children }) => {
  const className = classNames([styles.container, styles[type]]);

  if (children) return children;

  return (
    <div className={className}>
      <Typography variant="regular" size="14">
        {message}
      </Typography>
    </div>
  );
};

export default Snackbar;
