import { getLocalize } from '@utils/locales';
import Typography from '@components/typography';
import styles from './index.module.css';

const l = getLocalize('game.history.details.combined');

const Combined = ({ hex, decimal, multiplierCrash }) => (
  <div className={styles.container}>
    <div className={styles.item}>
      <Typography variant="regular" size="14">
        <span className={styles.label}>{l('hex')}</span>
      </Typography>
      <Typography variant="regular" size="14">
        {hex}
      </Typography>
    </div>

    <div className={styles.item}>
      <Typography variant="regular" size="14">
        <span className={styles.label}>{l('decimal')}</span>
      </Typography>
      <Typography variant="regular" size="14">
        {String(decimal)}
      </Typography>
    </div>

    <div className={styles.item}>
      <Typography variant="regular" size="14">
        <span className={styles.label}>{l('result')}</span>
      </Typography>
      <Typography variant="regular" size="14">
        {multiplierCrash}x
      </Typography>
    </div>
  </div>
);

export default Combined;
