import { getLocalize } from '@utils/locales';
import Icon from '@components/icon';
import Typography from '@components/typography';
import styles from './index.module.css';

const l = getLocalize('component.error.disconnect');

const Disconnect = () => (
  <div className={styles.container}>
    <div className={styles.icon}>
      <Icon variant="destructive" icon="Wifi" size="large" />
    </div>

    <div className={styles.text}>
      <Typography variant="bold" size="24">
        {l('title')}
      </Typography>

      <Typography variant="regular" size="18">
        {l('description')}
      </Typography>
    </div>
  </div>
);

export default Disconnect;
