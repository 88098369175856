const ClockFilled = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} fill="none">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.49 14.956a.751.751 0 0 1-1.028.258l-3.175-1.9a.749.749 0 0 1-.365-.644V8.581a.75.75 0 1 1 1.5 0v3.664l2.809 1.682a.75.75 0 0 1 .259 1.029ZM12 2.5c-5.239 0-9.5 4.262-9.5 9.5 0 5.239 4.261 9.5 9.5 9.5 5.238 0 9.5-4.261 9.5-9.5 0-5.238-4.262-9.5-9.5-9.5Z"
      clipRule="evenodd"
    />
  </svg>
);

export default ClockFilled;
