import { ACTIONS, CHANNELS, ERRORS } from '@utils/enums';
import { getLocalize } from '@utils/locales';
import { socket } from '@utils/socket';
import { appState, appResources } from '@utils/store';

export const listenAutoCashout = (callback) => {
  socket.on(CHANNELS.AUTO_CASH_OUT, callback);

  return () => {
    socket.off(CHANNELS.AUTO_CASH_OUT, callback);
  };
};

export const playBetSound = () => {
  if (!appState.settings.sound) return;
  const audio = appResources.audios.bet;

  audio.customStart();
};

let listenerCallback = {};

export const betPlaced = ({ betId, action }) =>
  new Promise((resolve) => {
    const channel = action === ACTIONS.BET ? CHANNELS.PLACE_BET_LOCAL : CHANNELS.CLEAR_BET_LOCAL;
    if (listenerCallback[betId]) socket.off(channel, listenerCallback[betId]);

    const PLACE_LOCAL = (data) => {
      listenerCallback[betId] = null;
      resolve(data);
    };

    socket.once(channel, PLACE_LOCAL);
    listenerCallback[betId] = PLACE_LOCAL;
  });

export const buttonVariantsMap = {
  [ACTIONS.BET]: 'bet',
  [ACTIONS.CANCEL]: 'cancel',
  [ACTIONS.CASH_OUT]: 'cashOut',
  [ACTIONS.WAITING]: 'waiting',
  FREE: 'freeBet',
};

const l = getLocalize('game.bet.error');

const errorMap = {
  [ERRORS.FUND_WITHDRAW_FAILED]: () => l('balance'),
  [ERRORS.FREEBETS_EXPIRED]: () => l('freeBet'),
};

const skipErrors = [ERRORS.EXPECTED_BETTING_PHASE];

export const showError = (error) => {
  if (skipErrors.includes(error)) return;

  const message = errorMap[error]?.() || l('global');
  if (!message) return;

  appState.snackbar.open({ type: 'error', message });
};
