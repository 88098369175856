const Shield = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} fill="none">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m11.528 14.245-2.952-2.957 1.062-1.06 1.891 1.895 3.897-3.9 1.061 1.06-4.959 4.962ZM3.882 3.093v9.55c0 6.86 8.142 9.404 8.224 9.429l.145.044.145-.044c.082-.025 8.222-2.57 8.222-9.43v-9.55H3.882Z"
      clipRule="evenodd"
    />
  </svg>
);

export default Shield;
