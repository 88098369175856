import { useState, useMemo } from 'react';
import { getLocalize } from '@utils/locales';
import Scroll from '@components/scroll';
import Tabs from '@components/tabs';
import Active from './parts/active';
import History from './parts/history';
import styles from './index.module.css';

const l = getLocalize('game.freeBets');

const Content = () => {
  const [tab, setTab] = useState('active');

  const tabs = useMemo(
    () => [
      { name: 'active', label: l('tab.active') },
      { name: 'history', label: l('tab.history') },
    ],
    []
  );

  return (
    <div className={styles.container}>
      <Tabs tab={tab} setTab={setTab} tabs={tabs} />

      <Scroll>{tab === 'active' ? <Active /> : <History />}</Scroll>
    </div>
  );
};

export default Content;
