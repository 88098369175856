import { useEffect, useState } from 'react';

const useDeviceType = () => {
  const [deviceType, setDeviceType] = useState(null);

  useEffect(() => {
    const setDevice = () => {
      if (window.matchMedia('(min-width: 1024px)').matches) setDeviceType('desktop');
      else if (window.matchMedia('(min-width: 600px) and (orientation: portrait)').matches) setDeviceType('tablet');
      else if (window.matchMedia('(min-width: 600px) and (orientation: landscape)').matches) setDeviceType('landscape');
      else return setDeviceType('mobile');
    };

    setDevice();

    window.addEventListener('resize', setDevice);

    return () => {
      window.removeEventListener('resize', setDevice);
    };
  }, []);

  return deviceType;
};

export default useDeviceType;
