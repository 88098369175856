import { useContext } from 'react';
import { getLocalize, localize } from '@utils/locales';
import { appState } from '@utils/store';
import { SettingsContext } from '@context/settings';
import Icon from '@components/icon';
import Typography from '@components/typography';
import ProvablyFair from '@game/parts/provably-fair';
import AviatorStudio from './parts/aviator-studio';
import styles from './index.module.css';

const l = getLocalize('game.footer');

const Footer = () => {
  const { settings } = useContext(SettingsContext);
  const { dark } = settings;

  const onClick = () => {
    const element = <ProvablyFair dark={dark} />;
    appState.modal.open({ element, label: localize('game.provablyFair.title') });
  };

  return (
    <div className={styles.container}>
      <div className={styles.provablyFair} onClick={onClick}>
        <Icon variant="success" icon="Shield" />
        <Typography variant="regular" size="12" underline>
          {l('provablyFair')}
        </Typography>
      </div>

      <AviatorStudio />
    </div>
  );
};

export default Footer;
