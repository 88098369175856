import { useContext } from 'react';
import { ACTIONS } from '@utils/enums';
import { classNames } from '@utils/helpers';
import { localize } from '@utils/locales';
import Typography from '@components/typography';
import { ActionContext } from '../../../context/action';
import { FreeBetContext } from '../../../context/free-bet';
import Toggle from '../components/toggle';
import styles from './index.module.css';

const Check = () => {
  const { action } = useContext(ActionContext);
  const { isFreeBet, setIsFreeBet } = useContext(FreeBetContext);
  const disabled = action !== ACTIONS.BET;

  const className = classNames([styles.container, disabled && styles.disabled]);

  return (
    <div className={className}>
      <Typography variant="regular" size="12">
        {localize('game.bet.freeBetCheck')}
      </Typography>
      <Toggle checked={isFreeBet} onChange={setIsFreeBet} />
    </div>
  );
};

export default Check;
