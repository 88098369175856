import Typography from '@components/typography';
import styles from './index.module.css';

const Item = ({ prefix, label, suffix, onClick }) => (
  <div className={styles.container} onClick={onClick}>
    {prefix}
    <Typography variant="regular" size="14">
      {label}
    </Typography>
    <div>{suffix}</div>
  </div>
);

export default Item;
