import { onGlobalError } from './global';
import { onNetworkError } from './network';

export const socketError = (setState) => {
  const listeners = [];

  listeners.push(...onNetworkError());
  listeners.push(onGlobalError(setState));

  return () => {
    listeners.forEach((listener) => listener());
  };
};
