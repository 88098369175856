import { useState, useEffect } from 'react';
import ButtonIcon from '@components/button-icon';
import { exitFullscreen, openFullscreen, canFullScreen } from './helpers';

const FullScreen = () => {
  const [expanded, setExpanded] = useState(false);

  const onClick = () => {
    setExpanded((prev) => !prev);
    if (expanded) exitFullscreen();
    else openFullscreen();
  };

  useEffect(() => {
    const onChange = () => {
      if (!document.fullscreenElement) setExpanded(false);
    };

    // TODO: add support for other browsers
    document.addEventListener('fullscreenchange', onChange);

    return () => {
      document.removeEventListener('fullscreenchange', onChange);
    };
  }, [expanded]);

  if (!canFullScreen()) return null;

  return <ButtonIcon variant="ghost" icon={expanded ? 'Collapse' : 'Expand'} onClick={onClick} />;
};

export default FullScreen;
