import { getLocalize } from '@utils/locales';
import Empty from '@components/empty';
import Skeleton from '@components/skeleton';
import Typography from '@components/typography';
import Item from '../item';
import styles from './index.module.css';

const l = getLocalize('game.placedBets.round.table');

const Table = ({ items }) => {
  if (!items) return <Skeleton />;

  if (!items.length) return <Empty label={l('empty')} />;

  return (
    <div className={styles.table}>
      <div className={styles.header}>
        <Typography variant="regular" size="12">
          {l('player')}
        </Typography>
        <Typography variant="regular" size="12">
          {l('bet')}
        </Typography>
        <Typography variant="regular" size="12">
          {l('win')}
        </Typography>
      </div>

      <div className={styles.bets}>
        {items.map((item, index) => (
          <Item key={index} {...item} />
        ))}
      </div>
    </div>
  );
};

export default Table;
