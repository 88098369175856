const ServerPrivacy = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} fill="none">
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.333}
      d="M20.333 10.919V8.25c0-2.613-1.85-4.251-4.468-4.251H8.801C6.183 4 4.333 5.63 4.333 8.25v7.498c0 2.62 1.85 4.252 4.468 4.252h2.74"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.333}
      d="M17.343 19.691s2.61-.79 2.61-2.97c0-2.178.096-2.109-.114-2.32-.21-.211-2.153-.887-2.496-.887-.342 0-2.286.678-2.496.887-.209.21-.114.141-.114 2.32 0 2.18 2.61 2.97 2.61 2.97Z"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.333}
      d="M8.212 8.325h.464m-.464 7.351h.464M12.425 8.325h4.03M12.529 12H4.354"
    />
  </svg>
);

export default ServerPrivacy;
