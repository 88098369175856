import { useEffect } from 'react';
import { appState } from '@utils/store';
import useMultiplier from '@hooks/socket/useMultiplier';

const CashOut = ({ betValue, autoCashout, setLoading }) => {
  const { maxWin } = appState.session.configs;

  const multiplier = useMultiplier();

  useEffect(() => {
    if (!autoCashout) return;
    setLoading(autoCashout <= multiplier);
  }, [autoCashout, multiplier, setLoading]);

  const coefficient = autoCashout ? Math.min(multiplier, autoCashout) : multiplier;

  return Math.min(coefficient * betValue, maxWin).toFixed(2);
};

export default CashOut;
