import { useContext, useEffect, useState } from 'react';
import { PhaseContext } from '@context/phase';
import useMultiplier from '@hooks/socket/useMultiplier';
import Bottom from './parts/bottom';
import Left from './parts/left';

const Bullets = ({ resolution, exploded, dark }) => {
  const multiplier = useMultiplier();
  const phase = useContext(PhaseContext);

  const [animateX, setAnimateX] = useState(false);
  const [animateY, setAnimateY] = useState(false);

  useEffect(() => {
    if (multiplier > 1.6) setAnimateY(true);
    else setAnimateY(false);

    if (multiplier > 1.15) setAnimateX(true);
    else setAnimateX(false);
  }, [multiplier]);

  useEffect(() => {
    if (phase === 'GAME_ENDED') {
      setAnimateX(false);
      setAnimateY(false);
    }
  }, [phase]);

  if (exploded) return null;

  return (
    <>
      <Bottom dark={dark} resolution={resolution} animate={animateX} />
      <Left dark={dark} resolution={resolution} animate={animateY} />
    </>
  );
};

export default Bullets;
