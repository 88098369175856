import { localize } from '@utils/locales';
import { appState } from '@utils/store';
import IconContainer from '@components/icon-container';
import ListItem from '@components/list-item';
import Content from './parts/content';

const Limits = () => {
  const onClick = () => {
    const element = <Content />;
    appState.modal.open({ element, label: localize('game.limits.title') });
  };

  return (
    <ListItem
      variant="ghost"
      prefix={<IconContainer icon="Coins" size="medium" />}
      label={localize('game.menu.limits')}
      onClick={onClick}
    />
  );
};

export default Limits;
