import { createRoot } from 'react-dom/client';
import 'core-js/stable';
import ErrorBoundary from '@components/error/boundary';
import Modal from '@components/modal';
import Snackbars from '@components/snackbars';
import App from './App';
import './index.css';

const rootElement = document.getElementById('root');

createRoot(rootElement).render(
  // <StrictMode>
  <>
    <ErrorBoundary>
      <App />
      <Snackbars />
      <Modal />
    </ErrorBoundary>
  </>
  // </StrictMode>
);
