export const getNumberInput = ({ inputValue, input }) => {
  if (input === '.') {
    if (!inputValue.includes('.')) return inputValue + input;
    return inputValue;
  }

  if (input === 'backspace') return inputValue.slice(0, -1);
  if (inputValue.includes('.') && inputValue.split('.')[1].length === 2) return inputValue;
  if (input === '0' && inputValue.includes('.')) return inputValue + input;

  return Number(inputValue + input).toString();
};
