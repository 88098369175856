import { useState, useMemo, useEffect } from 'react';
import { getLocalize } from '@utils/locales';
import { appState } from '@utils/store';
import Scroll from '@components/scroll';
import Tabs from '@components/tabs';
import My from './parts/my';
import Round from './parts/round';
import styles from './index.module.css';

const l = getLocalize('game.placedBets');

const PlacedBets = ({ overScroll }) => {
  const [tab, setTab] = useState(appState.placedBets.tab || 'current');

  useEffect(() => {
    appState.placedBets.tab = tab;
  }, [tab]);

  const tabs = useMemo(
    () => [
      { name: 'current', label: l('tab.current') },
      { name: 'my', label: l('tab.my') },
    ],
    []
  );

  return (
    <div className={styles.container}>
      <Tabs tab={tab} setTab={setTab} tabs={tabs} />

      <div className={styles.tab}>
        <Scroll hideShadow overScroll={overScroll}>
          {tab === 'current' ? <Round /> : <My />}
        </Scroll>
      </div>
    </div>
  );
};

export default PlacedBets;
