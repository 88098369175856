import Typography from '@components/typography';
import styles from './index.module.css';

const Chip = ({ onClick, children, prefix, suffix }) => (
  <button className={styles.container} onClick={onClick}>
    {prefix && <div className={styles.prefix}>{prefix}</div>}

    <Typography variant="regular" size="12">
      {children}
    </Typography>

    {suffix && <div className={styles.suffix}>{suffix}</div>}
  </button>
);

export default Chip;
