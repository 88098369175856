import { classNames } from '@utils/helpers';
import { getLocalize } from '@utils/locales';
import Typography from '@components/typography';
import styles from './index.module.css';

const l = getLocalize('game.bet');

// variant: bet | freeBet | waiting | cashOut | cancel;
const Button = ({ variant, disabled, loading, children, onClick }) => {
  const className = classNames([
    styles.container,
    styles[variant],
    loading && styles.loading,
    disabled && styles.disabled,
  ]);

  const contentRender =
    variant === 'cancel' ? (
      <Typography variant="regular" size="20">
        {l(variant)}
      </Typography>
    ) : (
      <>
        <Typography variant="regular" size="16">
          {l(variant)}
        </Typography>
        <Typography variant="bold" size="24">
          {children}
        </Typography>
      </>
    );

  return (
    <div className={className}>
      <button className={styles.button} onClick={onClick}>
        {contentRender}
      </button>
    </div>
  );
};

export default Button;
