import { classNames } from '@utils/helpers';
import styles from './index.module.css';

// size = small | medium | large | dynamic

const Avatar = ({ name, size = 'medium', active, onClick }) => {
  const className = classNames([styles.container, active && styles.active, styles[size], onClick && styles.clickable]);

  return (
    <div className={className} onClick={() => onClick?.()}>
      <img className={styles.image} src={`/avatars/${name}.jpg`} alt={name} />
    </div>
  );
};

export default Avatar;
