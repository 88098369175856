import { classNames } from '@utils/helpers';
import { getLocalize } from '@utils/locales';
import useMultiplier from '@hooks/socket/useMultiplier';
import Typography from '@components/typography';
import styles from './index.module.css';

const l = getLocalize('game.display');

const Multiplier = ({ exploded }) => {
  const multiplier = useMultiplier();

  const className = classNames([styles.container, exploded && styles.exploded]);

  const multiplierValue = multiplier.toFixed(multiplier >= 10000 ? 0 : 2);

  return (
    <div className={className}>
      <span className={styles.multiplier}>{multiplierValue}x</span>

      {exploded && (
        <span className={styles.text}>
          <Typography variant="bold" size="24">
            {l('flew')}
          </Typography>
        </span>
      )}
    </div>
  );
};

export default Multiplier;
