import { classNames } from '@utils/helpers';
import Typography from '@components/typography';
import styles from './index.module.css';

const Chip = ({ variant, onClick, children }) => {
  const className = classNames([styles.container, styles[variant]]);

  return (
    <button className={className} onClick={onClick}>
      <Typography variant="bold" size="12">
        {children}
      </Typography>
    </button>
  );
};

export default Chip;
