import { appResources } from '@utils/store';
import { resumeOnUnlock, createAudio } from './helpers';
import resources from './resources';

const preloadAudio = ({ name, src, type }) =>
  new Promise((resolve, reject) => {
    const audio = createAudio({ name, src, type });

    audio.once('load', () => {
      appResources.audios[name] = audio;
      resolve();
    });

    audio.once('loaderror', reject);

    resumeOnUnlock(audio, name);
  });

const preload = async () => {
  const promises = resources.map((src) => preloadAudio(src));
  return Promise.allSettled(promises);
};

export default preload;
