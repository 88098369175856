import { useCallback, useEffect, useRef } from 'react';
import { Graphics, useTick } from '@pixi/react';

const Left = ({ resolution, dotCount = 9, animate, dark }) => {
  const { height } = resolution;
  const graphicsRef = useRef(null);

  const size = 2;

  const distance = height / (dotCount - 2);

  useEffect(() => {
    graphicsRef.current.position = { x: size, y: 0 };
  }, []);

  useTick((delta) => {
    if (!animate) return;
    const container = graphicsRef.current;

    const speed = 0.3 * delta;
    container.y += speed;
    const lastY = distance - 20;
    if (container.y > lastY) container.y = lastY - distance + speed;
  });

  const drawBullets = useCallback(
    (graphic) => {
      graphic.clear();

      for (let i = -2; i < dotCount - 2; i++) {
        const x = 0;
        const y = i * distance;

        graphic.beginFill(dark ? 0xffffff : 0x000000);
        graphic.drawCircle(x, y, size);
        graphic.endFill();
      }
    },
    [dark, dotCount, distance]
  );

  return <Graphics ref={graphicsRef} draw={drawBullets} />;
};

export default Left;
