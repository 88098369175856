import { getLocalize } from '@utils/locales';
import Chip from '@components/chip';
import Icon from '@components/icon';
import Typography from '@components/typography';
import Table from '../table';
import useRoundBets from './parts/useRoundBets';
import styles from './index.module.css';

const l = getLocalize('game.placedBets.round.current');

const Current = ({ setTab }) => {
  const bets = useRoundBets();

  return (
    <div className={styles.container}>
      <div className={styles.total}>
        <Typography variant="bold" size="12">
          {l('total')}
          {bets?.length ? `: ${bets.length}` : ''}
        </Typography>

        <Chip onClick={() => setTab('previous')} prefix={<Icon icon="ClockRewind" size="xSmall" />}>
          {l('previous')}
        </Chip>
      </div>
      <Table items={bets} />
    </div>
  );
};

export default Current;
