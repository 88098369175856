import { useState } from 'react';
import { getLocalize } from '@utils/locales';
import { appState } from '@utils/store';
import Avatar from '@components/avatar';
import Button from '@components/button';
import Scroll from '@components/scroll';
import Typography from '@components/typography';
import { avatarNames } from '../../helpers';
import styles from './index.module.css';

const l = getLocalize('game.avatar');

const ChangeAvatar = ({ avatar, setAvatar }) => {
  const [selected, setSelected] = useState(avatar);

  const onClick = (name) => {
    setSelected(name);
  };

  const onSave = () => {
    localStorage.setItem('avatar', String(selected));
    setAvatar(selected);
    appState.modal.close();
  };

  return (
    <div className={styles.container}>
      <Scroll hideShadow>
        <div className={styles.items}>
          {avatarNames.map((name) => (
            <Avatar key={name} name={name} active={name === selected} size="dynamic" onClick={() => onClick(name)} />
          ))}
        </div>
      </Scroll>

      <Button onClick={onSave}>
        <Typography variant="bold" size="16">
          {l('save')}
        </Typography>
      </Button>
    </div>
  );
};

export default ChangeAvatar;
