import { useCallback, useEffect, useRef } from 'react';
import { Graphics, useTick } from '@pixi/react';

const Left = ({ resolution, dotCount = 12, animate, dark }) => {
  const { width, height } = resolution;
  const graphicsRef = useRef(null);

  const size = 2;

  const distance = width / (dotCount - 2);

  useEffect(() => {
    graphicsRef.current.position = { x: 20, y: height - size };
  }, [height]);

  useTick((delta) => {
    if (!animate) return;
    const container = graphicsRef.current;

    const speed = 0.25 * delta;
    container.x -= speed;

    const lastX = 20;

    if (container.x < lastX) container.x = distance + lastX - speed;
  });

  const drawBullets = useCallback(
    (graphic) => {
      graphic.clear();
      for (let i = 0; i < dotCount; i++) {
        const y = 0;
        const x = i * distance;

        graphic.beginFill(dark ? 0xffffff : 0x000000);
        graphic.drawCircle(x, y, size);
        graphic.endFill();
      }
    },
    [dark, dotCount, distance]
  );

  return <Graphics ref={graphicsRef} draw={drawBullets} />;
};

export default Left;
