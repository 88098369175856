import resources from './resources';

const preloadFonts = (src) =>
  new Promise((resolve, reject) => {
    const font = new FontFace('myfont', `url(${src})`);

    font.load().then(resolve).catch(reject);
  });

const preload = async () => {
  const promises = resources.map((src) => preloadFonts(src));
  return Promise.allSettled(promises);
};

export default preload;
