import { Howl, Howler } from 'howler';
import { isTouchDevice } from '@utils/helpers';

const errorListener = {};

const mute = {
  sound: localStorage.getItem('menu_sound') === 'false',
  music: localStorage.getItem('menu_music') === 'false',
};

export const createAudio = ({ name, src, type }) => {
  const audio = new Howl({
    src: [src],
    html5: true,
    preload: 'auto',
    autoplay: false,
    loop: false,
    mute: mute[type],
  });

  audio.customStart = (id) => {
    if (audio.locked) {
      audio.playErrorTime = Date.now();
      return id;
    }

    return audio.play(id, false);
  };

  audio.customStop = (id) => {
    audio.stop(id);
  };

  audio.name = name;
  audio.resourceType = type;
  audio.offsetTime = 0;

  return audio;
};

export const resumeOnUnlock = (audio, name) => {
  let unlockListener;

  const onPlayError = (id) => {
    if (unlockListener) audio.off('unlock', unlockListener);
    audio.locked = true;

    audio.playErrorTime = Date.now();

    const onUnlock = () => {
      audio.locked = false;

      const unlockTime = (Date.now() - audio.playErrorTime) / 1000;
      const offsetTime = audio.offsetTime + unlockTime;

      if (audio.duration(id) < offsetTime) return;
      audio.seek(offsetTime, id);
      requestAnimationFrame(() => audio.play(id));
    };

    audio.once('unlock', onUnlock);
    unlockListener = onUnlock;
  };

  if (!errorListener[name]) audio.on('playerror', onPlayError);
  errorListener[name] = onPlayError;
};

const muteOnBlur = () => {
  const callback = () => Howler.mute(document.hidden);

  document.addEventListener('visibilitychange', callback);

  return () => {
    document.removeEventListener('visibilitychange', callback);
  };
};

if (isTouchDevice) muteOnBlur();
