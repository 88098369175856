import { createContext, useCallback, useState, useEffect } from 'react';
import { appState } from '@utils/store';
import { muteSounds, muteMusic } from '@context/settings/helpers';

export const SettingsContext = createContext(null);

const settingKeys = ['sound', 'music', 'animation', 'dark'];

const initSetting = () => {
  let settings = {};

  settingKeys.forEach((key) => {
    const item = localStorage.getItem('menu_' + key);

    if (item) settings[key] = item === 'true';
    else {
      localStorage.setItem('menu_' + key, 'true');
      settings[key] = true;
    }
  });

  return settings;
};

const SettingsProvider = ({ children }) => {
  const [settings, setSettings] = useState(initSetting());

  const { sound, music } = settings;

  const setSetting = useCallback(
    (name, value) => {
      if (settings[name] === value) return;
      localStorage.setItem(`menu_${name}`, value);
      setSettings((prev) => ({ ...prev, [name]: value }));
    },
    [settings]
  );

  useEffect(() => {
    appState.settings = settings;
  }, [settings]);

  useEffect(() => {
    const dataTheme = settings.dark ? 'dark' : 'light';
    document.documentElement.setAttribute('data-theme', dataTheme);
  }, [settings.dark]);

  useEffect(() => {
    muteSounds(sound);
  }, [sound]);

  useEffect(() => {
    muteMusic(music);
  }, [music]);

  return <SettingsContext.Provider value={{ settings, setSetting }}>{children}</SettingsContext.Provider>;
};

export default SettingsProvider;
