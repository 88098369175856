import Animation from './parts/animation';
import Text from './parts/text';

const Loading = () => (
  <>
    <Text />
    <Animation />
  </>
);

export default Loading;
