import { useEffect, useState } from 'react';
import Table from '../table';
import { setActiveBets } from './helpers';

const Active = () => {
  const [freeBets, setFreeBets] = useState(null);

  useEffect(() => setActiveBets(setFreeBets), []);

  return <Table items={freeBets} />;
};

export default Active;
