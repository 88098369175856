import { useCallback, useRef } from 'react';
import { Graphics } from '@pixi/react';

const Trace = ({ resolution, coordinates }) => {
  const ref = useRef(null);

  const { height } = resolution;

  const { x, y } = coordinates;

  const draw = useCallback(
    (graphic) => {
      graphic.clear();

      const cxp = x * 0.4;
      const cyp = height * 0.98;

      graphic.beginFill(0xff0000, 0.5);
      graphic.moveTo(0, height);
      graphic.lineTo(x, height);
      graphic.lineTo(x, y);
      graphic.quadraticCurveTo(cxp, cyp, 0, height);
      graphic.endFill();

      graphic.moveTo(0, height);
      graphic.lineStyle({ width: 2, color: 0xff0000 });
      graphic.quadraticCurveTo(cxp, cyp, x, y);
    },
    [height, x, y]
  );

  if (x < 0 || y > resolution.height) return null;

  return <Graphics ref={ref} draw={draw} />;
};

export default Trace;
