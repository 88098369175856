import { classNames } from '@utils/helpers';
import styles from './index.module.css';

const Item = ({ isSuccess, onClick, children }) => {
  const className = classNames([styles.container, isSuccess && styles.success, onClick && styles.clickable]);

  return (
    <div className={className} onClick={onClick}>
      {children}
    </div>
  );
};

export default Item;
