import { useState, useEffect } from 'react';
import { useTick } from '@pixi/react';
import { calculateXY, animateExplode, getPlaneWidth, getPlaneHeight } from '../helpers';

const defaultCoordinate = ({ height, width }) => ({
  x: -getPlaneWidth(),
  y: height + getPlaneHeight({ height, width }),
});

const useCoordinates = ({ resolution, exploded, dark }) => {
  const [coordinates, setCoordinates] = useState(defaultCoordinate(resolution));

  useEffect(() => {
    if (exploded) return;
    setCoordinates(defaultCoordinate(resolution));
  }, [exploded, resolution]);

  useTick((...tickArgs) => {
    setCoordinates((prev) => {
      if (!exploded) return calculateXY({ coordinates: prev, dark, resolution, tickArgs });
      if (exploded) return animateExplode({ coordinates: prev, resolution, tickArgs });
    });
  });

  return coordinates;
};

export default useCoordinates;
