const Server = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} fill="none">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.136 3.75a2.174 2.174 0 0 0-2.17 2.178c0 1.196.958 2.162 2.14 2.179h9.789a2.174 2.174 0 0 0 2.14-2.179 2.174 2.174 0 0 0-2.17-2.178h-9.73Zm0 5.857h-.03a2.174 2.174 0 0 0-2.14 2.178c0 1.205.973 2.178 2.169 2.178h4.828a.778.778 0 0 1 .043 0h4.858a2.174 2.174 0 0 0 2.17-2.178 2.174 2.174 0 0 0-2.14-2.178H7.137Zm-3.67-3.679c0 1.194.568 2.256 1.448 2.928a3.677 3.677 0 0 0-1.448 2.929c0 2.029 1.64 3.678 3.669 3.678h4.1v1.686c-.7.235-1.251.79-1.485 1.489H5.744a.75.75 0 0 0 0 1.5H9.75a2.357 2.357 0 0 0 4.47 0h4.004a.75.75 0 0 0 0-1.5H14.22a2.365 2.365 0 0 0-1.486-1.49v-1.685h4.13a3.674 3.674 0 0 0 3.67-3.678 3.676 3.676 0 0 0-1.448-2.928 3.677 3.677 0 0 0 1.448-2.929c0-2.029-1.64-3.678-3.669-3.678h-9.73a3.674 3.674 0 0 0-3.668 3.678Zm8.52 12.598a.859.859 0 0 0-.857.862c0 .478.385.861.856.861a.859.859 0 0 0 .856-.861V19.362a.859.859 0 0 0-.856-.836Zm-4.363-7.49a.75.75 0 1 0 0 1.5h1.46a.75.75 0 0 0 0-1.5h-1.46Zm0-5.857a.75.75 0 1 0 0 1.5h1.46a.75.75 0 0 0 0-1.5h-1.46Z"
      clipRule="evenodd"
    />
  </svg>
);

export default Server;
