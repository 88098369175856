import { useEffect, useRef } from 'react';
import useResolution from '@hooks/useResolution';
import { createAnimation } from './helpers';
import styles from './index.module.css';

const Animation = () => {
  const containerRef = useRef(null);
  const resolution = useResolution('display');

  useEffect(() => {
    if (!resolution) return;
    return createAnimation(containerRef.current);
  }, [resolution]);

  return <div className={styles.container} ref={containerRef} />;
};

export default Animation;
