import { useContext, useEffect, useMemo } from 'react';
import { PHASES } from '@utils/enums';
import { PhaseContext } from '@context/phase';
import { playSound, getSounds } from './helpers';

const Sound = () => {
  const phase = useContext(PhaseContext);

  const sounds = useMemo(() => getSounds(), []);

  useEffect(() => {
    playSound(sounds.background, 0, true);
  }, [sounds.background]);

  useEffect(() => {
    if (phase === PHASES.BETTING) playSound(sounds[PHASES.BETTING]);
    if (phase === PHASES.GAME_ENDED) playSound(sounds[PHASES.GAME_ENDED]);
  }, [sounds, phase]);

  return null;
};

export default Sound;
