import { memo } from 'react';
import Comet from './parts/comet';
import styles from './index.module.css';

const items = [...new Array(2)];

const Comets = () => (
  <div className={styles.container}>
    {items.map((_, index) => (
      <Comet key={index} delay={index * 5} />
    ))}
  </div>
);

export default memo(Comets);
